import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet, StatusBar, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchDraftsMessageFromApi, fetchMoreDraftsMessageFromApi } from '../../../shared/actions/DraftsMessageAction';
import { clearSetItemMessage, setItemMessage } from '../../../shared/actions/SetMessageItemAction';
import MessageItem from "../../components/MessageItem";
import { getInitials } from '../../../shared/core/utils';

import FileBase64 from 'react-file-base64';
import { Link } from 'react-router-dom';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';
import { Modal, Select, Button } from 'antd';
import { msgClenuplatData, sendNewMessagetoAPI } from '../../../shared/actions/NewMessageActions';
import RoseAlert from '../../components/RoseAlert';
const Drafts = props => {
    const { Profile } = props.Profile;
    //const { SentItems, isGetting } = props.SentItems;
    const { DraftsMessaging, isGetting } = props.DraftsMessaging;
    const { SendMessage, isSending, isSendMessageSuccess, error } = props.SendMessage
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    useEffect(() => {
        // console.log(props.testValue)
        props.getDraftsMessage(pageSize, pageNumber)
    }, [])

    ///////////
    const saveDraft = () => {
        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            return
        }



        // let sub = document.getElementById('userSubject1').value
        if (subject === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            return
        }

        let msg = document.getElementById('userMsg1').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(drftid, centerName, centerRef, subject, msg, fileType, fileBase, filesList, resourceType, recpName, 'Drafts', sendMessageSuccesfully)

    }
    const checkFile = (file) => {
        if (file) {
            for (var i = 0; i < file.length; i++) {
                setFileSize(file[i].size);
                setfileType(file[i].name.split('.').pop())
                setFileBase(file[i].base64)
                let ob = {
                    type: file[i].name.split('.').pop(),
                    size: file[i].size.split(' ')[0] * 1024,
                    base64: file[i].base64.split(',').pop(), //reader.result.split(',').pop(),
                };
                filesList.push(ob);
            }
            //console.log({ filesList })

        }



    }
    const [centerName, setCenterName] = useState('');
    const [centerRef, setCenterRef] = useState('');
    const [centersArray, setCentersArray] = useState([]);
    const [providerArray, setProviderArray] = useState([]);
    const [resourceType, setResourceType] = useState('');
    const [recpName, setRecpName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    const [fileSize, setFileSize] = useState('');
    const [fileType, setfileType] = useState('');
    const [fileBase, setFileBase] = useState('');
    const [selected, setSelected] = useState()
    const [filesList, setFilesList] = useState([]);
    const [isReady, setIsReady] = useState(false)
    const [newMsgModal, setNewMsgModal] = useState(false);
    const [drftid, setDraft] = useState(null)
    const [loadingMessage, setLoadingMessage] = useState(false);


    useEffect(() => {
        if (providerArray && providerArray.length) {
            // setSelected(providerArray[0].resource.name[0].text)
            // setRecpName(providerArray[0].resource.name[0].text);
            // setResourceType(providerArray[0].resource.resourceType + '/' + providerArray[0].resource.id);


        }

    }, [providerArray])
    function onChangeProvider(value) {
        setSelected(providerArray[value].resource.name[0].text)
        document.getElementById('errorProvider').innerHTML = ''
        setRecpName(providerArray[value].resource.name[0].text);
        setResourceType(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);


        //console.log(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);
        //console.log(providerArray[value].resource.name[0].text)
    }
    function onBlurProvider() {
        //console.log('blur');
    }

    function onFocusProvider() {
        //CallcenterList()
        //console.log('focus provider');
    }
    useEffect(() => {
        CallcenterList()
    }, [])
    const [centerLoading, setCenterLoading] = useState(false)
    const CallcenterList = () => {

        setProviderArray([]);
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setCenterLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&TypeID=1';
            //console.log(queryString)
            //console.log(item)
            // document.getElementById('error').innerHTML = ''
            fetch(Settings.apiUrl + "Patient/Location" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    let ob;
                    let newarr = []
                    // console.log("Center arrray",arr)
                    json[0].entry.map((entry, item) => (
                        ob = {
                            name: entry?.resource?.name,
                            id: entry?.resource?.identifier[0]?.value
                        },
                        newarr.push(ob)
                    ))
                    console.log("Center arrray", newarr)
                    // setMoveDayLookup(medicationsDays != undefined ? getOptionsArray(medicationsDays, 'DayName', 'TreatmentDaysID') : [])
                    setCentersArray(newarr)
                    // setCentersArray(json[0].entry)
                    setCenterLoading(false)
                })
                .catch(err => {
                    //console.log(err)
                    setCenterLoading(false)
                })
        })
    }
    const [providerLoading, setProviderLoading] = useState(false)
    const [selectedCenter, setSelectedCenter] = useState()
    React.useEffect(() => {
        if (centersArray && centersArray.length) {

            // CallProviderList(centersArray[0].id)
            // setSelectedCenter(centersArray[0]?.name)
            //console.log(centersArray[value]);
            // let id = centersArray[0]?.id;
            // // let resourceType = centersArray[value].resource.resourceType;
            // setCenterName(centersArray.find(centers => centers.id == id).name)
            // setCenterRef(id);
        }

    }, [centersArray])
    // React.useEffect(() => {
    //     if (isSendMessageSuccess) {
    //         alert("isSendMessageSuccess")
    //         setNewMsgModal(false)
    //         document.getElementById('userSubject1').value = ''
    //         document.getElementById('userMsg1').value = ''
    //         clearSelected()
    //         setSelectedCenter(null)
    //         props.cleandata()
    //         // RoseAlert()
    //         // openNotificationWithIcon('success')

    //     }
    //     // 
    // }, [isSendMessageSuccess])
    const sendMessageSuccesfully = () => {
        // setNewMsgModal(false)
        window.location.reload()
        setNewMsgModal(false)
        // document.getElementById('userSubject1').value = ''
        // document.getElementById('userMsg1').value = ''
        // clearSelected()
        // setSelectedCenter(null)
        // props.cleandata()
        // props.getDraftsMessage(pageSize, pageNumber)

    }
    const getMessageId = msgValue => {
        setNewMsgModal(true);
        setLoadingMessage(true)
        getUserId().then((userInfo) => {
            // setLoading(true)
            // const item = JSON.parse(userInfo);
            const item = userInfo !== "none" && JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = "?Token=" + item.token + "&PatientID=" + item.userid + "&MessageID=" + msgValue.resource.id;

            fetch(`${Settings.apiUrl}` + "Patient/MessageByID" + queryString, requestOptions) // ?PatientID=" + item.userid + "&MessageID=" + msgValue, requestOptions)
                .then(data => data.json())
                .then(json => {

                    // console.log("Response==>", JSON.stringify(json[0].entry[0]))
                    setSelectedCenter(json[0]?.entry[0]?.resource?.extension[9]?.valueReference?.reference?.split('/')[1])
                    setCenterRef(json[0]?.entry[0]?.resource?.extension[9]?.valueReference?.reference?.split('/')[1])
                    CallProviderList(json[0]?.entry[0]?.resource?.extension[9]?.valueReference?.reference?.split('/')[1])
                    setSelected(json[0]?.entry[0]?.resource?.recipient[0]?.reference?.split('/')[1].toString())
                    setRecpName(json[0]?.entry[0]?.resource?.recipient[0]?.display);
                    setResourceType(json[0]?.entry[0]?.resource?.recipient[0]?.reference);
                    // setSubject({ value: json[0].entry[0].resource.note[0].text, error: '' })
                    // setUserMessage({ value: json[0].entry[0].resource.extension[0].valueString, error: '' })
                    // setfiles(json[0].entry[0].resource.hasOwnProperty('payload') ? json[0].entry[0].resource.payload : [])
                    json[0].entry[0].resource.hasOwnProperty('payload') &&
                        json[0].entry[0].resource.payload.map((it, index) => {

                            //   base64files.push(item.contentAttachment.data)
                            let ob = {
                                type: it.contentAttachment.contentType,
                                size: it.contentAttachment.size,
                                base64: it.contentAttachment.data, // file[i].base64.split(',').pop(), //reader.result.split(',').pop(),
                            };
                            filesList.push(ob)
                            // alert('files')
                            console.log({ filesList })

                        })
                    setDraft(msgValue.resource.id)
                    setLoadingMessage(false)


                    // setCenterRef(json[0]?.entry[0]?.resource?.extension[0]?.valueString)
                    setSubject(json[0]?.entry[0]?.resource?.note[0]?.text)
                    // document.getElementById('userSubject1').value = json[0]?.entry[0]?.resource?.note[0]?.text;
                    document.getElementById('userMsg1').value = json[0]?.entry[0]?.resource?.extension[0]?.valueString

                    // setTimeout(() => {
                    //     document.getElementById('userSubject1').value = msgValue.resource.note[0].text;
                    //     document.getElementById('userMsg1').value = msgValue.resource.extension[0].valueString

                    // }, 2000);
                    setIsReady(!isReady)
                    // DarftId = json[0].entry[0].resource.id
                    // json[0].entry[0].resource.hasOwnProperty('payload') &&
                    //     // setIsSelected(true)
                    //     setIsReady(!isReady)
                    // console.log("for Hassan====>  " + JSON.stringify(item))

                    // setLoading(false)
                })
                .catch(error => {
                    // setLoading(false)
                    console.log('error', error)
                })
        })




        // props.setMessageItem(params, 'Drafts')

        // 
        // setSubject(item.resource.note[0].text)
        // setMessage(item.resource.extension[0].valueString)
    }
    function onChange(value) {
        setResourceType('')
        clearSelected();
        // alert(value)
        setSelectedCenter(value)
        //console.log(centersArray[value]);
        let id = value// centersArray[value].resource.extension[0].valueString;
        // let resourceType = centersArray[value].resource.resourceType;
        setCenterRef(id);
        // setCenterName(centersArray[value].resource.name)
        CallProviderList(id)
    }
    const CallProviderList = (index) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setProviderLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = "?Token=" + item.token + "&PatientID=" + item.userid + '&PracticeLocationID=' + index;
            // const queryString = "?Token=" + item.token + "&PatientID=" + item.userid + "&RoleID=" + index + "&CallType=0";
            // const queryString = '?PatientID=' + item.userid + '&PracticeLocationID=' + index;
            //console.log(queryString)
            fetch(Settings.apiUrl + "Patient/MessagingRecipients" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    setProviderLoading(false);
                    //console.log(json[0].entry)
                    setProviderArray(json[0].entry);
                })
                .catch(err => {
                    setProviderLoading(false)
                    //console.log(err)
                })
        })

    }
    function onBlur() {
        //console.log('blur');
    }

    function onFocus() {
        //    CallcenterList()
        //console.log('focus');
    }
    const clearSelected = () => {
        // this line will clear the select
        // when you click on the button
        // setSelected(null)
    }

    const sendMessage = () => {
        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            alert("Please select Center");
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            alert("Please select Provider");
            return
        }



        // let sub = document.getElementById('userSubject1').value
        if (subject === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            alert("Please select subject");
            return
        }

        let msg = document.getElementById('userMsg1').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            alert("Please select message");
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(drftid, centerName, centerRef, subject, msg, fileType, fileBase, filesList, resourceType, recpName, 'Send Mail', sendMessageSuccesfully)
    }
    const [subjectList, setSubjectList] = useState([])
    useEffect(() => {
        LoadMessageSubject()
        return () => {

        };
    }, [])
    const LoadMessageSubject = () => {
        // https://nhldevconnect.indici.ie/api/Patient/GetMessageSubject?Token=e5a4b191-6884-48f4-bc4c-b3682be33e9f
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setProviderLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // const queryString = "?Token=" + item.token + "&PatientID=" + item.userid + "&RoleID=" + centerRef index
            const queryString = "?Token=" + item.token // "&PatientID=" + item.userid + '&PracticeLocationID=' + index;
            //console.log(queryString)
            // const queryString = "cPatientID=" + item.userid + "&RoleID=" + index
            fetch(Settings.apiUrl + "Patient/GetMessageSubject" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    setProviderLoading(false);
                    //console.log(json[0].entry)
                    let list = []
                    json[0]?.entry.map((entry) => (
                        list.push({ value: entry?.resource?.extension[0]?.valueString, name: entry?.resource?.extension[1]?.valueString })
                    ))
                    console.log("list", list)
                    setSubjectList(list) //entry
                    // setProviderArray(json[0].entry);
                })
                .catch(err => {
                    setProviderLoading(false)
                    //console.log(err)
                })
        })

    }
    // const getMessageId = params => {
    //     console.log("for Hassan====>  " + params)
    //     props.setMessageItem(params, 'Drafts')
    // }
    return (
        <>
            {
                isGetting && <View style={{ textAlign: 'center', marginTop: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (DraftsMessaging) !== 'undefined' && DraftsMessaging.length ? (
                    <FlatList
                        style={{ marginTop: 0, height: 700 }}
                        data={DraftsMessaging}
                        renderItem={({ item }) => (
                            <TouchableOpacity onPress={() => {
                                // alert(item.resource.id)
                                getMessageId(item)
                            }}>
                                {/* // <Link to={{
                            //     pathname: '/read',
                            //     state: { "id": item.resource.id, "reply": true }
                            // }}> */}
                                <MessageItem
                                    initails={getInitials(item.resource.extension[2].valueString)}
                                    messageDate={GetAppointmentDate(item.resource.extension[6].valueDateTime) + ' ' + GetMonthName(item.resource.extension[6].valueDateTime)}
                                    sender={item.resource.extension[2].valueString}
                                    messageSubject={item.resource.note[0].text}
                                    messageDescription={item.resource.extension[0].valueString}
                                />
                            </TouchableOpacity>
                            // </Link>
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        windowSize={7}

                    />

                ) : !isGetting && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>
            }
            <Modal
                title="New Message"
                centered
                visible={newMsgModal}
                onOk={() => {
                    sendMessage()
                    //   setNewMsgModal(false);

                }}
                loading={isSending}
                onCancel={() => setNewMsgModal(false)}
                closable={false}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
                footer={[
                    // <div style={{flexDirection:'column', justifyContent:'space-between'}} >

                    isSending ?
                        <ActivityIndicator size="small" color="#00A1DE" />

                        : <>
                            <Button key="back" onClick={() => {
                                // document.getElementById('userSubject1').value = null
                                // document.getElementById('userMsg1').value = null
                                // setFilesList([])
                                // setNewMsgModal(false)
                                window.location.reload()
                            }}>
                                Cancel
                            </Button>
                            <Button key="submit" type="secondary" onClick={() => saveDraft()}>
                                Draft
                            </Button>
                            <Button key="submit" type="primary" onClick={() => sendMessage()}>
                                Send
                            </Button>
                        </>




                    // </div>
                ]}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">

                        <div class="col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">

                                {
                                    typeof Profile !== "undefined" && Profile.length ? (
                                        // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                        Profile[0].resource.extension.map((item, index) => {

                                            return (
                                                item.url == "MessageDisclaimer" &&
                                                <div class="row mb-2">
                                                    {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                    <div class="col-md-8 mx-auto">
                                                        <div class="alert alert-warning" role="alert">
                                                            <h6 class="alert-heading">Disclaimer:</h6>

                                                            <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                            )
                                        })
                                    ) : null
                                }

                            </div>
                        </div>

                        {loadingMessage ?
                            <ActivityIndicator size='small' color="blue" /> :
                            <>
                                <div style={{}} className="form-group row mb-0">
                                    <div className="col-md-6">
                                        {/* <div className="form-group input-recipient"> showcase_content_area */}
                                        <label className="label" style={{ width: 68 }}>To</label>
                                        {/* <div class="col-md-6 "> */}
                                        {centerLoading && <ActivityIndicator size="small" color="#00A1DE" />}
                                        {centersArray && centersArray.length ?
                                            <Select
                                                defaultValue={centersArray[0].id}
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Select a person"
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                onFocus={onFocus}
                                                onBlur={onBlur}
                                                value={selectedCenter}

                                            >
                                                {
                                                    centersArray.map((item, index) => <Select.Option value={item?.id} key={index}>{item?.name}</Select.Option>)
                                                }

                                            </Select>
                                            : !centerLoading &&
                                            <p>No registered Role Available.</p>
                                        }

                                        <p id="error" style={{ color: 'red' }}></p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group input-recipient" style={{ paddingBottom: 4 }}>
                                            <label className="label" style={{}}>Select Provider</label>
                                            <div class="showcase_content_area">

                                                {providerLoading && <ActivityIndicator size="small" color="#00A1DE" />
                                                }
                                                {providerArray && providerArray.length ?
                                                    <Select
                                                        showSearch
                                                        defaultValue={providerArray[0].resource.name[0].text}
                                                        style={{ width: '100%' }}
                                                        placeholder="Select Provider"
                                                        optionFilterProp="children"
                                                        onChange={onChangeProvider}
                                                        onFocus={onFocusProvider}
                                                        onBlur={onBlurProvider}
                                                        value={selected}


                                                    >
                                                        {providerArray !== undefined && providerArray.map((item, index) => <Select.Option value={item?.resource.id} key={index}>{item.resource.name[0].text}</Select.Option>)}

                                                    </Select>
                                                    : !providerLoading && <p>No registered provider available.</p>

                                                }
                                                <p id="errorProvider" style={{ color: 'red' }}></p>

                                            </div>
                                        </div>


                                    </div>

                                </div>


                                <div style={{}} className="form-group row mb-0">

                                    <div className="form-group col-md-12">
                                        <label className="label" style={{ width: 68 }}>Subject</label>
                                        {
                                            subjectList && subjectList.length ?
                                                <Select
                                                    showSearch
                                                    defaultValue={subjectList[0].name}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Subject"
                                                    optionFilterProp="children"
                                                    onChange={(res) => {
                                                        setSubject(res)
                                                    }}
                                                    // onFocus={onFocusProvider}
                                                    // onBlur={onBlurProvider}
                                                    value={subject}
                                                >
                                                    {subjectList !== undefined && subjectList.map((item, index) => <Select.Option key={index} value={item?.name}>{item.name}</Select.Option>)}

                                                </Select>
                                                : !providerLoading && <p>No registered provider available.</p>

                                        }

                                        {/* <input type="text" className="form-control "
                                            placeholder="Subject" id="userSubject1" //
                                            onClick={() => document.getElementById('errorSub').innerHTML = ''} /> */}
                                        <p id="errorSub" style={{ color: 'red' }}></p>
                                    </div>
                                </div>
                                <div style={{}} className="form-group row mb-0">
                                    <div className="form-group col-md-12">

                                        <textarea id="userMsg1" className="form-control" rows={8} placeholder="Write your message here" defaultValue={""} onClick={() => document.getElementById('errorMsg').innerHTML = ''} />
                                        <p id="errorMsg" style={{ color: 'red' }}></p>
                                    </div>


                                </div>
                                {/* <p>jj</p> */}
                                <FileBase64
                                    className="action-buttons"
                                    multiple={true}
                                    onDone={(files) => checkFile(files)} />
                                <div style={{ alignItems: 'flex-end', }} className="form-group row mb-0">

                                    <div>


                                        <div className="email-preview-wrapper" style={{ padding: 0 }}>
                                            <div className="email-container" style={{ margin: 0 }}>
                                                <div className="email-attachments">
                                                    <div className="attachment-wrapper mt-2" style={{ marginTop: 0, paddingTop: 0 }}>
                                                        {filesList && filesList.length ?
                                                            filesList.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        onClick={() => { }}
                                                                        className={
                                                                            item.type ==
                                                                                "PDF"
                                                                                ? "attachment attachment-warning"
                                                                                : "attachment attachment-primary"
                                                                        }>

                                                                        <p>
                                                                            Document.
                                                                            {item.type}
                                                                        </p>
                                                                        <span>Attachment {index + 1}</span>
                                                                        <div className="icon-wrapper">
                                                                            {item.type ==
                                                                                "PDF" ? (
                                                                                <i className="mdi mdi mdi-file-pdf" />
                                                                            ) : (
                                                                                <i className="mdi mdi mdi-file-image" />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : null
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                    </div>


                </div>

            </Modal >


        </>
    );
}





function mapStateToProps(state) {
    return {
        //SentItems: state.SentItems,
        DraftsMessaging: state.DraftsMessaging,
        SendMessage: state.SendMessage,
        Profile: state.Profile,
        //SetMessageItem: state.SetMessageItem,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendData: (draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType, sendMessageSuccesfully) => dispatch(sendNewMessagetoAPI(draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType, sendMessageSuccesfully)),

        getDraftsMessage: (pageSize, pageNumber) => dispatch(fetchDraftsMessageFromApi(pageSize, pageNumber)),
        setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
        // getMoreDraftsMessageMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreDraftsMessageFromApi(pageSize, pageNumber)),
        //setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
        // setScreenName: (screenName) => dispatch(setScreenName(screenName)),
        //clearSetMessage: () => dispatch(clearSetItemMessage()),
        cleandata: () => dispatch(msgClenuplatData()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Drafts)

//export default Drafts
