import React, { useCallback, useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchOverdueImmune } from "../../../shared/actions/OverdueImmuneActions";

const OverdueTab = props => {

    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(1);
    const { OverdueImmune, isGettingImmuneOverdue } = props.OverdueImmune;


    useEffect(() => {
        //alert('Called Future')
        props.getImmuneOverdue(pageSize, pageNumber);
    }, [])

    return (
        <div>
            {
                isGettingImmuneOverdue && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (OverdueImmune) !== 'undefined' && OverdueImmune.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        data={OverdueImmune}
                        renderItem={({ item }) => (
                            <ImmuneHandler
                                styles={'card-header text-white bg-danger'}
                                heading={item.resource.extension[1].valueString} //{GetHeading(item)}
                                date={
                                    GetAppointmentDate(item.resource.date) +
                                    " " +
                                    GetMonthName(item.resource.date) +
                                    " " +
                                    GetYear(item.resource.date)
                                  }
                                //    date={GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime)+ ' '+GetYear(item.resource.extension[7].valueDateTime)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                // type_diagnoes={item.resource.extension[0].valueString}
                                //indication={item.resource.extension[5].hasOwnProperty('valueString') ? item.resource.extension[5].valueString : '-'}
                                //vacc={item.resource.extension[6].hasOwnProperty('valueString') ? item.resource.extension[6].valueString : '-'}
                                indication={' N/A '}
                                vacc={' N/A '}
                                outcome={item.resource.extension[14].hasOwnProperty('valueString') ? item.resource.extension[14].valueString : 'N/A'}
                                datedue={'Was due: ' + GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}

                    />
                ) : !isGettingImmuneOverdue && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>

            }
        </div>
    );
}



function mapStateToProps(state) {
    return {
        OverdueImmune: state.OverdueImmune
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getImmuneOverdue: (pageSize, pageNumber) => dispatch(fetchOverdueImmune(pageSize, pageNumber)),
        // getImmuneMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreOverdueImmune(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OverdueTab)

// export default OverdueTab
