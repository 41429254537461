import { START_VALIDATION, START_VALIDATION_SUCCESS, START_VALIDATION_FAILED } from "../constants/Constant";

const initailState = {
    TokenValidator: false,
    isChecking: false,
    error: false,
}


export default function tokenValidReducer(state = initailState, action) {
    switch (action.type) {
        case START_VALIDATION:
            return {
                ...state,
                isChecking: true
            }

        case START_VALIDATION_SUCCESS:
            return {
                ...state,
                isChecking: false,
                TokenValidator: action.data
            }

        case START_VALIDATION_FAILED:
            return {
                ...state,
                isChecking: false,
                error: true
            }

        default:
            return state
    }
}
