import { GETTING_MESSAGE_BY_ID,GETTING_MESSAGE_BY_ID_SUCCESS,GETTING_MESSAGE_BY_ID_FAILED, GETTING_ALLERGIES_FAILED,GETTING_MESSAGE_BY_ID_CLEAN } from "../constants/Constant";
import getPaitentMsg from "../services/MessagingService";
import getUserId from "../core/GetToken";
import getMessageByID from "../services/MessageById";

// use in InboxWebScreen for modal and set the data
// use in EditDraftWebHandler 


export const fetchMessageById = (messageID) => {
    return (dispatch) => {
        dispatch(cleanItemMessageById)
        dispatch(gettingMessageById())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&MessageID=' + messageID;
            console.log(queryString);
            getMessageByID(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(gettingMessageByIdSuccess(json[0].entry)) : dispatch(getMessageByIdFinsihed())
                })
                .catch(err => {
                    console.log("error"+JSON.stringify(err))
                    dispatch(gettingMessageByIdFail(err))
                })
        })
    }
}


export const cleanDetailMessageitem = () =>{
    return(dispatch)=>{
        dispatch(cleanItemMessageById)
    }
}

const gettingMessageById = () => {
    return {
        type: GETTING_MESSAGE_BY_ID
    }
}

//data success
const gettingMessageByIdSuccess = (data) => {
    return {
        type: GETTING_MESSAGE_BY_ID_SUCCESS,
        data,
    }
}
// messiginWebScreen
const cleanItemMessageById = () =>{
    return{
        type:GETTING_MESSAGE_BY_ID_CLEAN
    }
}

//data failed
const gettingMessageByIdFail = (err) => {
    return {
        type: GETTING_MESSAGE_BY_ID_FAILED,
        data:err
    }
}

const getMessageByIdFinsihed = () => {
    return "null";
}


