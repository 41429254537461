import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { clearUserState, fetchUserInformation, LogoutUser } from "../../shared/actions/AuthActions";
import { AuthContext } from "../../shared/core/context";
import getUserId from "../../shared/core/GetToken";
import $ from "jquery";
//import FlatList from 'flatlist-react';

import { View, TouchableOpacity, FlatList, ActivityIndicator, Text } from "react-native-web";
import { emailValidator, getInitials, passwordValidator } from '../../shared/core/utils';
import ProxyAccountWebHandler from "../components/ProxyAccountWebHandler";
import { Redirect, useHistory } from "react-router-dom";

import AsyncStorage from "@react-native-async-storage/async-storage";

//import {  } from "react-bootstrap/Moda";
import { Modal, Button } from "react-bootstrap";
import ForgetPassword from './ForgetPassword';
import changePasswrodService from '../../shared/services/ChangePasswrod';
import Settings from '../../shared/config/Settings';

//export default function Login() {
const Login = props => {
    const linkHistory = useHistory();

    useEffect(() => {


        //$("#email2").bind("paste", function () { return false; });
        // $("#email2").bind("copy", function () { return false; });

        //document.getElementById("mPassword").bind("paste", function () { return false; });
        //document.getElementById("mPassword").bind("copy", function () { return false; });

        //const myInput = document.getElementById('mPassword');
        //myInput.onpaste = e => e.preventDefault();
        //myInput.oncopy = e => e.preventDefault();

        // props.getProxy();
    }, []);

    const people = [
        { firstName: 'Elson', lastName: 'Correia', info: { age: 24 } },
        { firstName: 'John', lastName: 'Doe', info: { age: 18 } },
        { firstName: 'Jane', lastName: 'Doe', info: { age: 34 } },
        { firstName: 'Maria', lastName: 'Carvalho', info: { age: 22 } },
        { firstName: 'Kelly', lastName: 'Correia', info: { age: 23 } },
        { firstName: 'Don', lastName: 'Quichote', info: { age: 39 } },
        { firstName: 'Marcus', lastName: 'Correia', info: { age: 0 } },
        { firstName: 'Bruno', lastName: 'Gonzales', info: { age: 25 } },
        { firstName: 'Alonzo', lastName: 'Correia', info: { age: 44 } }
    ]


    const renderPerson = (person, idx) => {
        return (

            <li onClick={() => listOnClick(person.resource.id)} key={idx}>

                <b>{person.resource.id} </b> (<span>Testing</span>)

            </li >

        );
    }


    const listOnClick = (id) => {
        alert(id);
    }

    const [isRedirected, setIsRedirect] = useState(false)
    const { isLoggedIn, isLoggin, AuthUser, error, passworderror, loginFailed, userPasswordStatus } = props.AuthUser;
    const { signIn, signOut } = React.useContext(AuthContext);
    const [Proxy, setProxy] = useState('');
    const [userAgrement, setUserAgrement] = useState(false)
    const [privacyStatementCheck, setPrivacyStatemCheck] = useState(false)
    const [firstState, setFirstState] = useState(false);
    const processLogin = (e) => {

        let useName = document.getElementById("mUserName").value;
        let password = document.getElementById("mPassword").value;

        const emailError = emailValidator(useName);
        const pawrdEror = passwordValidator(password);

        if (emailError || pawrdEror) {
            document.getElementById("errorMessage").innerHTML = "You have entered an invalid email address.";
            return;
        }
        document.getElementById("errorMessage").innerHTML = "";
        props.getUserToken(useName, password)
        // setShow(true)
    }


    const [showReset, setShowReset] = useState(false);
    const [show, setShow] = useState(false);

    const [isResetPassword, setIsResetPassword] = useState(false)
    const [termnConditonVisible, setTermnConditonVisible] = useState(false)


    const handleClose = () => setShow(false);
    // this method is used for to show the handleclose terma and conditon modal 
    // this is not used now because we add the check box in the reset passwrod modal.
    const handleCloseTemnCond = () => {
        props.clearResetState()
        setShowReset(false)
        setTermnConditonVisible(false)
        testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };
    const handleCloseReset = () => {
        props.clearResetState()
        setShowReset(false)
        // use for to show the term and condition modal
        // setTermnConditonVisible(true)
        testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };

    const handleShow = () => setShow(true);


    // const testFunction = () => {
    //     console.log("Called");
    // }

    useEffect(() => {

    }, [showReset])

    const testFunction = (userToken, userId) => {

        // alert("Proxy Check Called==>" + userToken + ",,," + userId);

        // var formdata = new FormData();

        var requestOptions = {
            method: 'GET',
            // body: formdata,
            redirect: 'follow'
        };

        fetch(Settings.apiUrl + "Patient/ProxyUsers?Token=" + userToken + "&PatientID=" + userId, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result[0].total === 1) {
                    //console.log('yes')
                    getUserId().then((userInfo) => {
                        const item = JSON.parse(userInfo);

                        //if the account self is patient
                        if (item.userid === result[0].entry[0].resource.id) {
                            //save single patient information
                            // _saveData(item.token, item.userid, item.email, item.password, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)
                            _saveData(item.token, item.userid, item.email, item.password, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)

                            console.log('yes')
                        }
                        else {
                            _saveDataSingleProxy(item.token, result[0].entry[0].resource.id, item.email, item.password, "Yes", result[0].entry[0].resource.name[0].text, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)

                            console.log('yes')

                        }
                    })
                }
                else {
                    console.log('NO')
                    setProxy(result[0].entry)
                    setShow(true)
                    setFirstState(true);
                }
            })
            .catch(error => console.log('error', error));
    }


    const _setDataState = async (proxyUserId, proxyUserName, proxyid) => {
        let jsonValue = '';
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            console.log("Firsts :" + JSON.stringify(item))
            _saveData(item.token, proxyUserId, item.email, item.password, "Yes", proxyUserName, item.parentid, proxyid, item.parentname)

        })
    };


    const _saveDataSingleProxy = async (token, proxyUserId, pemail, ppassword, isProxy, proxyUserName, parentid, proxyid, parentName) => {

        // alert(isProxy)
        await AsyncStorage.clear();
        try {
            console.log(isProxy)
            const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "password": ppassword, "proxy": "Yes", "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName });
            console.log(jsonValue)
            await AsyncStorage.setItem('@userToken', jsonValue)
            loadNext();
        } catch (error) {
            // Error saving data
        }
    }

    const _saveData = async (token, proxyUserId, pemail, ppassword, isProxy, proxyUserName, parentid, proxyid, parentName) => {

        // alert(isProxy)
        await AsyncStorage.clear();
        try {
            console.log(isProxy)
            const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "password": ppassword, "proxy": isProxy, "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName });
            console.log(jsonValue)
            await AsyncStorage.setItem('@userToken', jsonValue)
            loadNext();
        } catch (error) {
            // Error saving data
        }
    }
    //************************************** End Proxey account **************************************/

    const loadReset = () => {
        // return (<Redirect to={"/resetpassword"} />)
        setShowReset(!showReset)
    }
    const closeResetModal = () => {
        // setShowReset(() => {
        //     return !showReset
        // })
        props.clearResetState()
        props.endUserSession();
        setShowReset(false)
        setShowReset((state) => {
            return state
        })

        signOut();
        // window.location.reload();




    }
    const loadNext = () => {

        setIsRedirect(true);
        setIsRedirect((state) => {
            return state;
        });
        signIn('baseuser');
        window.location.reload()
        //return (<Redirect to={"/"} />)
    }



    const resetPasswordFunc = async () => {
        //    alert("ok")


        let password = document.getElementById("password").value;
        let confirmPassword = document.getElementById("confirmPassword").value;

        const passwordError = passwordValidator(password);
        const cnfrmPaswrd = passwordValidator(confirmPassword);
        if (passwordError) {
            document.getElementById("errorMessageReset").innerHTML = "Password cannot be empty "
            return;
        }
        if (cnfrmPaswrd) {
            document.getElementById("errorMessageReset").innerHTML = "Confirm Password cannot be empty "
            return;
        }
        if (password !== confirmPassword) {
            document.getElementById("errorMessageReset").innerHTML = "Password can't be matched."
            return
        }
        //check for lower case
        if (!password.match(/[a-z]/)) {
            alert("Password must contain at least one lower case letter.");
            // passForm.passInput.focus();
            return;
        }

        //Validating length
        if (password.length < 8) {
            alert("Your password has less than 8 characters.");
            return;
        }

        //check for upper ase
        if (!password.match(/[A-Z]/)) {
            alert("Password must contain at least one upper case letter.");
            // passForm.passInput.focus();
            return;
        }
        var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
        //check for number
        if (!patt.test(password)) {
            alert("Password must contain at least two number.");
            // passForm.passInput.focus();
            return;
        }
        if (!privacyStatementCheck) {
            document.getElementById("errorMessageReset").innerHTML = "You need to mark that you agree before you can proceed."
            return;
        }
        if (!userAgrement) {
            document.getElementById("errorMessageReset").innerHTML = "You need to mark that you agree before you can proceed."
            return;
        }
        document.getElementById("errorMessageReset").innerHTML = ""
        setIsResetPassword(true)
        var userInfo = await AsyncStorage.getItem('@userPassordReset') || 'none';
        // check remining confirm and passweordk
        const item = JSON.parse(userInfo);
        const queryString = '?Token=' + item.token;
        console.log("ResetPasswrod===>" + JSON.stringify(item))
        // const queryString = '?Token=' + item.token;
        // console.log("ResetPasswrod===>" + password)

        var jsonPa = JSON.stringify({ "PatientID": item.userid, "Password": password })
        changePasswrodService(queryString, jsonPa)
            .then(data => data.json())
            .then(json => {
                // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
                if (json.StatusCode == "1") {
                    props.clearResetState()
                    setShowReset(false)
                    // for activity indicatorr
                    setIsResetPassword(false)
                    document.getElementById("errorMessageReset").innerHTML = "";
                    handleCloseReset()
                    console.log("statscode==1==>" + JSON.stringify(json))
                    // handleCloseReset()
                }
                // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
                if (json.StatusCode == "3") {
                    setIsResetPassword(false)
                    document.getElementById("errorMessageReset").innerHTML = json.StatusMessage;
                    console.log("statscode==2==>" + JSON.stringify(json))

                }
            })
            .catch(err => {
                setIsResetPassword(false)
                handleCloseReset()
                document.getElementById("errorMessageReset").innerHTML = "Some thing went wrong please try agian.";
                console.log("resetPasswrodError==>" + err)
            })
    }


    const clearCache = async () => {
        try {
            await AsyncStorage.clear()
        } catch (e) {
            // clear error
        }

        console.log('Done.')
    }



    const [showincon, setshowicon] = useState('mdi mdi-eye')
    const [showReseticon, setshowReseticon] = useState('mdi mdi-eye')
    const [showconfirmResticcon, setshowconfirmResticcon] = useState('mdi mdi-eye')
    const showPassword = () => {
        var x = document.getElementById("mPassword");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }
    const resetPasswordShow = (elementId) => {
        var x = document.getElementById(elementId);
        if (x.type === "password") {

            setshowReseticon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowReseticon('mdi mdi-eye')
            x.type = "password";
        }
    }
    const resetConfirmPasswordShow = (elementId) => {
        var x = document.getElementById(elementId);
        if (x.type === "password") {

            setshowconfirmResticcon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowconfirmResticcon('mdi mdi-eye')
            x.type = "password";
        }
    }
    // if (isRedirected === true) {
    //     return <Redirect to={'/'} />
    // }
    const handleCheckTermandCondition = () => {
        setUserAgrement(!userAgrement)
        console.log('CHANGE!');
    }
    const handlePrivacyStatement = () => {
        setPrivacyStatemCheck(!privacyStatementCheck)
        console.log('CHANGE!');
    }
    return (
        <div class="authentication-theme auth-style_3">
            <div class="row inner-wrapper">

                <div className="col-lg-5 col-md-7 col-sm-9 col-11 mx-auto form-section">
                    <div className="grid">
                        <div className="grid-body">
                            <div className="row">
                                <div className="col-lg-7 col-md-8 col-sm-9 col-12 mx-auto form-wrapper">

                                    <div className="form-group">
                                        <div className="col-12 logo-section">
                                            <a href="#" className="logo">
                                                <img src="https://devnhlapp.indici.ie/Images/mainLogo-indici-140X40.svg" alt="logo" />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="input-group">
                                            <input id="mUserName" type="text" className="form-control" placeholder="Username" />
                                            <div className="input-group-append">
                                                <div className="input-group-text"><i className="mdi mdi-email"></i></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input id="mPassword" type="password" className="form-control" placeholder="Password" />
                                            <div className="input-group-append">
                                                <div onClick={() => showPassword()} className="input-group-text"><i className={showincon}></i></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group input-rounded">
                                        <p id="errorMessage" style={{ color: 'red' }}></p>
                                    </div>

                                    {
                                        isLoggin && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                    }
                                    {
                                        loginFailed && <p id="userName_pass_Incorrect" style={{ color: 'red' }}>{passworderror}</p>
                                    }
                                    <button onClick={
                                        (e) => processLogin(e)



                                    } type="submit" className="btn btn-primary btn-block"> Login </button>
                                    {
                                        !showReset && userPasswordStatus && loadReset()
                                    }

                                    {
                                        //console.log(firstState)
                                        !userPasswordStatus && !show && isLoggedIn && testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)
                                        // !firstState && isLoggedIn && checkProxy(AuthUser[0].extension[0].valueString, AuthUser[0].id)
                                    }

                                    <div className="signup-link">

                                        <p>Forgot Password?</p>
                                        <Link to="/forgetPassword">
                                            Reset Now
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static"
                keyboard={false}>
                <Modal.Header className="modal-header bg-primary">
                    <Modal.Title className="modal-title text-white">Select An Account to Continue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        typeof (Proxy) !== 'undefined' && Proxy.length ? (
                            <FlatList
                                data={Proxy}
                                style={{
                                    flexDirection: 'column',
                                    // paddingLeft: 10,
                                    // paddingRight: 10,
                                    // paddingBottom: 10
                                }}
                                renderItem={({ item }) => (
                                    <TouchableOpacity onPress={() => _setDataState(item.resource.id, item.resource.name[0].text, item.resource.extension[0].valueString)}>
                                        <ProxyAccountWebHandler
                                            reInitails={getInitials(item.resource.name[0].text)}
                                            reReceiverName={item.resource.name[0].text}
                                            reType={item.resource.extension[1].valueString} />
                                    </TouchableOpacity>
                                )}
                                keyExtractor={item => item.resource.id}
                                horizontal={false}
                                initialNumToRender={30}
                                maxToRenderPerBatch={10}
                                windowSize={7}
                                onEndReachedThreshold={0.1}
                                onEndReached={() => {
                                    // Reports.length > 30 ? onLoadMore() : console.log('No Need');
                                }}
                            />
                        ) : null

                    }
                    {/* <ul>
                            <FlatList
                                list={Proxy}
                                renderItem={renderPerson}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                            //sortBy={["firstName", { key: "lastName", descending: true }]}
                            //groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                            />

                        </ul> */}
                </Modal.Body>

            </Modal>

            <Modal show={showReset} onHide={() => handleCloseReset()} backdrop="static" keyboard={false}>
                <Modal.Header className="modal-header bg-primary">
                    <Modal.Title className="modal-title text-white">Welcome to indici. Update your password to continue.</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div>
                        <View style={{ backgroundColor: "#d1ecf1", padding: 10, marginBottom: 5 }}>
                            <Text style={{ fontWeight: 'bold' }}>Password Rules</Text>
                            <Text style={{}}>Password must be at least 8 characters.</Text>
                            <Text style={{}}>Password must contain at least one alphabet and two digits.</Text>
                            <Text style={{}}>Password must not contain first, middle or family name.</Text>
                            <Text style={{}}>Example: abc12345 or Abc@1234</Text>
                        </View>
                        <div className="form-group ">

                            <div className="input-group">
                                <input id="password" type="password" className="form-control" placeholder="Enter password." />

                                <div className="input-group-append">
                                    <div onClick={() => {

                                        resetPasswordShow("password")
                                    }} className="input-group-text"><i className={showReseticon}></i></div>
                                </div>
                            </div>



                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input id="confirmPassword" type="password" className="form-control" placeholder="Confirm password." />

                                <div className="input-group-append">
                                    <div onClick={() => resetConfirmPasswordShow("confirmPassword")} className="input-group-text"><i className={showconfirmResticcon}></i></div>
                                </div>
                            </div>
                            {/* 
                            <div className="form-group input-rounded">

                            </div> */}
                        </div>
                        <div className="form-group input-rounded">
                            <p id="errorMessageReset" style={{ color: "red" }}></p>
                        </div>
                        {
                            isResetPassword && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                        }
                        <div style={{
                            display: "flex",
                            // justifyContent: "center",
                            width: 500,
                            alignContent: "center",
                        }}>
                            <input style={{ backgroundColor: 'red', }} onChange={handleCheckTermandCondition} type="checkbox" checked={userAgrement} />
                            <label style={{ fontWeight: 'bold', marginLeft: 10 }}>I have read and agree to the <a href="https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2Fmyindici%20Patient%20Portal%20User%20Agreement.pdf?alt=media&token=10d36f21-f4fe-4364-8205-82f5c2448c3f" target="_blank">myindici Patient Portal User Agreement</a></label>
                        </div>
                        <div style={{ display: "flex", alignContent: "center", }}>
                            <input style={{ backgroundColor: 'red', }} onChange={handlePrivacyStatement} type="checkbox" checked={privacyStatementCheck} />
                            <label style={{ fontWeight: 'bold', marginLeft: 10 }}>I have read and agree to the <a href="https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2Fmyindici%20Patient%20Portal%20%20Privacy%20Statement.pdf?alt=media&token=85224819-02cc-4bc9-93b3-db304e31f1ae" target="_blank">myindici Privacy Statement</a></label>
                        </div>
                        <div className="form-group input-rounded">
                            <p id="privacyMessage" style={{ color: "red" }}></p>
                        </div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: 'row',

                        }}>
                            <div style={{ display: 'flex', flex: 1 }}>

                            </div>
                            {/* <div style={{ display: 'flex', flex: 1, justifyContent: "flex-end" }}>
                                <button
                                    id="clickBtn"
                                    style={{
                                        borderWidth: 0,
                                        backgroundColor: "#00A1DE",
                                        color: 'white',
                                        marginRight: 10
                                    }} onClick={() => {
                                        if (userAgrement && privacyStatementCheck) {
                                            handleCloseReset()
                                            handleCloseTemnCond()
                                            return;
                                        } else {
                                            document.getElementById("privacyMessage").innerHTML = "You must have tick the privacy checks."
                                            return;
                                        }
                                    }}>
                                    Let's Get Started</button>
                                <button
                                    id="noButton"
                                    onClick={() => {
                                        setShowReset(false)
                                        setTermnConditonVisible(false)
                                        // handleCloseReset()
                                        linkHistory.push("/")
                                    }}
                                    style={{ width: 80, borderWidth: 0, backgroundColor: "#00A1DE", color: 'white' }}>No</button>
                            </div> */}
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flex: 1 }}>

                            </div>
                            <div style={{ display: 'flex', flex: 2, flexDirection: 'row', alignContent: "center", alignItems: 'center', justifyContent: 'center', }}>
                                <button onClick={() => resetPasswordFunc()} className="btn btn-primary btn-block">Proceed</button>
                                <button
                                    className="btn btn-primary btn-block"
                                    style={{ marginLeft: 10 }}
                                    id="noButton"
                                    onClick={() => {
                                        closeResetModal()
                                        // setShowReset(!showReset)
                                        // setTermnConditonVisible(false)
                                        // handleCloseReset()
                                        // linkHistory.push("/")
                                    }}>Cancel</button>
                            </div>

                        </div>



                    </div>
                </Modal.Body>

            </Modal>




            <Modal show={termnConditonVisible} onHide={handleCloseTemnCond} backdrop="static"
                keyboard={false}>
                <Modal.Header className="modal-header bg-primary">
                    <Modal.Title className="modal-title text-white">Welcome to inidici. Update your password to continue.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        display: "flex",
                        // justifyContent: "center",
                        // width: 500,
                        flexDirection: "column",
                        alignContent: "center",
                    }}>
                        {/* <Text>
                            Terms and Conditions Sample Generator
                            Help protect your website and its users with clear and fair website terms and conditions. These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss.

                            Making your own terms and conditions for your website is hard, not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out.

                            All you need to do is fill up the blank spaces and then you will receive an email with your personalized terms and conditions.

                            Looking for a Privacy Policy? Check out Privacy Policy Generator.

                            The accuracy of the generated document on this website is not legally binding. Use at your own risk.
                        </Text> */}
                        {/* <View style={{ flex: 1 }}>
                            <TouchableOpacity style={{ backgroundColor: "green", borderRadius: 5, alignSelf: 'baseline', padding: 10 }} onPress={() => {
                                handleCloseReset()
                                handleCloseTemnCond()
                            }}>
                                <View ><Text style={{ color: 'white', fontSize: 14 }}>Accept and Continue</Text>
                                </View>
                            </TouchableOpacity>
                        </View> */}

                    </div>
                </Modal.Body>

            </Modal>
        </div>
    );
}



function mapStateToProps(state) {
    return {
        AuthUser: state.AuthUser
    }
}

function mapDispatchToProps(disptach) {
    return {
        getUserToken: (email, password) => disptach(fetchUserInformation(email, password)),
        clearResetState: () => disptach(clearUserState()),
        endUserSession: () => disptach(LogoutUser()),
    }
}


//export default Login;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)