import React, { useCallback, useState, useEffect, useRef } from 'react';
import { View, Text, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';

import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'
import { fetchReportsFromApi, fetchMoreReportsFromApi } from "../../shared/actions/ReportsActions";
import ReportsHandler from "../components/ReportsHandler";

import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import ReportViewHandler from '../components/ReportViewHandler';
import getUserId from "../../shared/core/GetToken";

import renderHTML from "react-render-html";
import { useReactToPrint } from 'react-to-print';
import Settings from '../../shared/config/Settings';
import { getInitials } from '../../shared/core/utils';

const PatientResults = props => {
    const { Profile } = props.Profile;
    const [isloaded, setIsload] = React.useState(false);
    const { Reports, isFetching } = props.Reports;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);


    const [visible, setVisible] = useState(false);  //for report pdf view
    //for report virws
    const [authorName, setAuthorName] = useState('');
    const [labrotatyName, setlabrotatyName] = useState('');
    const [InsertedDate, setInsertedDate] = useState('');
    const [Observationtext, setObservationtext] = useState('');
    const [AbnormalFlagDesc, setAbnormalFlagDesc] = useState('');
    const [pdfViewerLoading, setPdfViewerLoading] = useState(false);

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        props.getReports(pageSize, pageNumber);
    }, [])


    const loadReport = (reportId) => {
        //setVisible(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        getUserId().then((userInfo) => {
            //setPdfViewerLoading(true)
            const item = JSON.parse(userInfo);
            fetch(Settings.apiUrl + "Patient/LabResultByID?Token=" + item.token + "&PatientID=" + item.userid + "&ResultID=" + reportId, requestOptions)
                .then(data => data.json())
                .then(json => {

                    setAuthorName(json[0].entry[0].resource.author[0].display);
                    setlabrotatyName(json[0].entry[0].resource.extension[0].valueString);
                    setInsertedDate(json[0].entry[0].resource.extension[1].valueString);
                    // setObservationtext(json[0].entry[0].resource.extension[2].valueString);
                    // setAbnormalFlagDesc(json[0].entry[0].resource.extension[3].valueString);

                    setObservationtext(json[0].entry[0].resource.description)
                    ///setPdfViewerLoading(false)

                    setVisible(true)
                })
                .catch(error => {
                    // setIsLoadingPdf(false)
                    console.log("error")
                    // setPdfViewerLoading(false)
                })
                .finally(() => {
                    // setCheckFile(false)
                    // setIsLoadingPdf(false)
                    //setPdfViewerLoading(false)


                });
        });
    }



    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Test Results</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            {
                                typeof Profile !== "undefined" && Profile.length ? (
                                    // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                    Profile[0].resource.extension.map((item, index) => {

                                        return (
                                            item.url == "TestResultDisclaimer" &&
                                            <div class="row mb-2">
                                                {isFetching && <ActivityIndicator color='blue' size='small' />}
                                                <div class="col-md-8 mx-auto">
                                                    <div class="alert alert-warning" role="alert">
                                                        <h6 class="alert-heading">Please Note:</h6>

                                                        <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                    </div>
                                                </div>
                                            </div>


                                        )
                                    })
                                ) : null

                            }
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Results & Reports</h2>
                                            <div className="item-wrapper">

                                                {
                                                    isFetching && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                }
                                                {
                                                    typeof (Reports) !== 'undefined' && Reports.length ? (
                                                        <FlatList
                                                            data={Reports}
                                                            style={{
                                                                flexDirection: 'column',
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                paddingBottom: 10
                                                            }}
                                                            renderItem={({ item }) => (
                                                                <TouchableOpacity onPress={() => loadReport(item.resource.id)}>
                                                                    <ReportsHandler
                                                                        rptDate={GetAppointmentDate(item.resource.created) + ' ' + GetMonthName(item.resource.created) + ' ' + GetYear(item.resource.created)}
                                                                        testName={item.resource.description}
                                                                        author={'Provider: ' + item.resource.author[0].display}
                                                                    />
                                                                </TouchableOpacity>
                                                            )}
                                                            keyExtractor={item => item.resource.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            windowSize={7}

                                                        />
                                                    ) : !isFetching && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                                    </View>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Modal
                        title="Result Details"
                        centered
                        visible={visible}
                        onOk={() => setVisible(false)}
                        onCancel={() => setVisible(false)}
                        width={1000}
                        footer={[
                            <Button key="back" onClick={() => setVisible(false)}>
                                Close
                            </Button>,
                            <Button key="submit" type="primary" onClick={() => handlePrint()}>
                                Print
                            </Button>,
                        ]}
                    >

                        <div ref={componentRef} className="email-preview-wrapper">
                            {/* <div className="preview-header">
                                <View style={styles.personNameCircle}>
                                    <View style={{
                                        backgroundColor: 'red',
                                        height: 40,
                                        width: 40,
                                        borderRadius: 40,
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Text style={styles.personNameText}>
                                            {getInitials(authorName)}
                                        </Text>
                                    </View>
                                </View>
                                <p classname="user_name" style={{ overflow: 'visible !important' }}> {authorName}</p>
                                <a className="user-email" href="#">{labrotatyName}</a>
                                <p className="date"> {InsertedDate}</p>
                            </div> */}

                            <div className="email-container">
                                <div className="email-content">
                                    <View >
                                        {/* <Text>Title</Text> */}
                                        <Text>{renderHTML(Observationtext)}</Text>
                                    </View>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}


function mapStateToProps(state) {
    return {
        Reports: state.Reports,
        Profile: state.Profile,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getReports: (pageSize, pageNumber) => disptach(fetchReportsFromApi(pageSize, pageNumber)),
        //  getMoreReports: (token, pageSize, pageNumber) => disptach(fetchMoreReportsFromApi(token, pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientResults)
// export default PatientResults


const styles = StyleSheet.create({
    personNameCircle: {
        width: 50,
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 2,
        paddingTop: 15
    },
    personNameText: {
        color: '#fff',
        fontSize: 14,
        // fontWeight:'bold'
    },

})
