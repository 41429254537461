import React from "react";

const Route = require("react-router-dom").Route;
const Switch = require("react-router-dom").Switch;
const Router = require("react-router-dom").BrowserRouter;

const Redirect = require("react-router-dom").Redirect;


const PrivateRoute = ({ component: Component, authed, ...rest }) => {

    // return (
    //     <Route {...rest} render={props => (
    //         isAuthenticated ? (<Component {...props} />
    //         ) : (
    //                 <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    //             )
    //     )} />
    // )

    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />
    )

}

export default PrivateRoute