import React, { useEffect } from 'react';

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Chart, Line } from 'react-chartjs-2';
import getUserId from '../../shared/core/GetToken';
import { connect } from "react-redux";
import { getVitalfromAPI } from '../../shared/actions/VitalsActions';
import { View, ActivityIndicator, FlatList, StyleSheet, Text, StatusBar } from "react-native-web";
import VitalHandler from '../components/VitalHandler';
import Settings from '../../shared/config/Settings';

const Item = ({ item }) => (
    <div>
        <div className="col-md-6">
            <div className="grid">
                <div className="grid-body">
                    <h2 className="grid-title">Blood Pressure</h2>
                    <div className="item-wrapper">
                        <canvas id={item.name}></canvas>
                        <p>hell</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Vitals = props => {
    const { isLoading, GetVital, wholeArray, error, errorMessage, isWholeSuccess } = props.VitalReducer

    const [isloaded, setIsload] = React.useState(false);

    let lables = [];
    let values = [];

    let weightLabel = [];
    let weightValues = [];

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
    }, [])

    useEffect(() => {

        props.getVital()

    }, [])

    // useEffect(() => {
    //     if (isWholeSuccess) {
    //         wholeArray.gradphData.map((value, key) => {
    //             console.log('myChart_' + key)

    //         })
    //     } else {
    //         console.log(isWholeSuccess)
    //     }
    // }, [isWholeSuccess])


    function fetchVitals(pageSize, ChartType, pageNumber) {
        // 1 for blood pressure.
        let pgSize = pageSize == undefined ? 30 : pageSize
        let pgNumber = pageNumber == undefined ? 1 : pageNumber
        let chartType = ChartType == undefined ? 0 : ChartType
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pgSize + '&PageNumber=' + pgNumber + '&ChartType=' + chartType;
            console.log(queryString)
            fetch(Settings.apiUrl + "Patient/Observation" + queryString)
                .then(data => data.json())
                .then(json => {
                    console.log("Data of vitals:", JSON.stringify(json));

                    var ob = {
                        text: item.resource.code.coding[0].display,
                        value: item.resource.component[0].valueQuantity.value,
                        inserteddate: item.resource.extension[0].valueDateTime
                    };








                    // gettingFormat(dispatch, json)
                    // dispatch(getVitalSuccess(json))
                })
                .catch(err => {
                    console.log("error" + JSON.stringify(err))
                })
        })

        // key = document.getElementById('myChart_'+{key}).getContext('2d');
        // var myChart = new Chart(key, {
        //     type: 'line',
        //     data: {
        //         labels: lables,
        //         datasets: [{
        //             label: '# of Votes',
        //             data: values,
        //             backgroundColor: [
        //                 'rgba(0, 0, 0, 0.1)'

        //             ],
        //             pointBackgroundColor: [
        //                 'rgba(0, 0, 0, 0.1)'
        //             ],
        //             borderColor: [
        //                 'rgba(255, 99, 132, 1)',
        //                 'rgba(54, 162, 235, 1)',
        //                 'rgba(255, 206, 86, 1)',
        //                 'rgba(75, 192, 192, 1)',
        //                 'rgba(153, 102, 255, 1)',
        //                 'rgba(255, 159, 64, 1)'
        //             ],
        //             borderWidth: 1
        //         }]
        //     },
        //     options: {
        //         tooltips: {
        //             mode: 'nearest'
        //         }
        //     }
        // });
    }


    const renderItem = ({ item }) => (
        // console.log("vitalLable",item.labels)
        <VitalHandler
            vitalName={item.name}
            vitalLabelsArray={item.labels}
            vitalDataArray={item.data} />
    );

    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    {
                        isLoading && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                    }
                    {/* {
                        !isLoading && console.log("test==>" + JSON.stringify(wholeArray))
                    } */}
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Vitals</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">

                                {/* <FlatList
                                    data={wholeArray}
                                    numColumns={2}
                                    renderItem={renderItem}
                                    keyExtractor={item => item.id}
                                /> */}

                                {
                                    typeof (wholeArray) !== 'undefined' && wholeArray.length ? (wholeArray.map((item) => {
                                        return (
                                            <VitalHandler
                                                vitalName={item.name}
                                                vitalLabelsArray={item.labels}
                                                vitalDataArray={item.data} />

                                        )
                                    })) : null}

                            </div>





                            {/* <div className="row">
                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Blood Pressure</h2>
                                            <div className="item-wrapper">
                                                <canvas id="myChart_1"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Blood Pressure</h2>
                                            <div className="item-wrapper">
                                                <canvas id="myChart_2"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Blood Pressure</h2>
                                            <div className="item-wrapper">
                                                <canvas id="myChart_3"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Blood Pressure</h2>
                                            <div className="item-wrapper">
                                                <canvas id="myChart_4"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Heart Rate</h2>
                                            <div className="item-wrapper">
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">BMI</h2>
                                            <div className="item-wrapper">
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> */}



                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: StatusBar.currentHeight || 0,
    },
    item: {
        backgroundColor: '#f9c2ff',
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    title: {
        fontSize: 32,
    },
});

// Please sir don't remove it i will test it latter. Hassan jan
function mapStateToProps(state) {
    return {
        VitalReducer: state.VitalReducer
    }
}


function mapDispatchToProps(disptach) {
    return {
        getVital: (pageSize, vitaltype) => disptach(getVitalfromAPI()),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Vitals)
