import { GETTING_PROFILE, GETTING_PROFILE_SUCCESS, GETTING_PROFILE_FAILURE } from "../constants/Constant";
import getPatientProfile from "../services/ProfileService";
import getUserId from "../core/GetToken";
//import getUserInfo from "../core/GetTokenized";



export const fetchProfileFromApi = () => {
    return (dispatch) => {
        dispatch(getProfile())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid;
            console.log('This is called from Immuni')
            // console.log(queryString);
            getPatientProfile(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log(json)
                    dispatch(getProfileSuccess(json[0].entry))
                })
                .catch(err => dispatch(getProfileFailure(err)))
        }).catch((err) => {
            console.log(err);
        })


    }
}




//state getting 
const getProfile = () => {
    return {
        type: GETTING_PROFILE
    }
}


//data success
const getProfileSuccess = (data) => {
    return {
        type: GETTING_PROFILE_SUCCESS,
        data,
    }
}


//data failed
const getProfileFailure = () => {
    return {
        type: GETTING_PROFILE_FAILURE
    }

}