import Settings from '../config/Settings'


const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

//var myHeaders = new Headers();
//myHeaders.append("Content-Type", "application/json");

//expor the dulft get fetch api call

export const postWithJson = (endpoint, bodyJson) => (
    fetch(`${Settings.apiUrl}${endpoint}`, {
        method: 'POST',
        headers: defaultHeaders,
        body: bodyJson,
    })
);

