import { GET_PROXY_ACCOUNTS, GET_PROXY_ACCOUNTS_SUCCESS, GET_PROXY_ACCOUNTS_FAILED, GET_PROXY_ACCOUNTS_FINISHED } from "../constants/Constant";
import getProxyAccess from "../services/ProxyService";
import getUserId from "../core/GetToken";


export const getProxyListApi = (pageNumber, pageSize) => {
    return (disptach) => {
        disptach(getProxyList())
        getUserId().then((userInfo) => {
            //console.log("tes"+userInfo)
            //  item = null;
            // try {
            const item =userInfo!=="none" && JSON.parse(userInfo);
            // }catch(e){
            //     item = JSON.parse(userInfo);
            // }
            // token = data
            const queryString = '?Token=' + item.token + '&PatientID=' + item.parentid;
            getProxyAccess(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log("DATAFOUND:"+json)
                    // disptach(getAlgSuccess(json[0].entry))
                    json[0].hasOwnProperty('entry') ? disptach(getProxyListSuccess(json[0].entry)) : disptach(proxyListEnd())
                })
                .catch(err => disptach(getProxyListFailed(err)))
           
        })

    }
}



const getProxyList = () => {
    return {
        type: GET_PROXY_ACCOUNTS
    }
}


const getProxyListSuccess = (data) => {
    return {
        type: GET_PROXY_ACCOUNTS_SUCCESS,
        data
    }
}

const getProxyListFailed = () => {
    return {
        type: GET_PROXY_ACCOUNTS_FAILED
    }
}

const proxyListEnd = () => {
    return {
        type: GET_PROXY_ACCOUNTS_FINISHED
    }
}