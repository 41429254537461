import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import DiagnosisHandler from "../components/DiagnosisHandler";
import { fetchLongTermFromApi } from "../../shared/actions/DiagnosisActions";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'

const Diagnosis = props => {

    const [isloaded, setIsload] = React.useState(false);

    const { LongDiag, isFetching } = props.LongDiag;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);



    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        //alert('Called Future')
        props.getPeopleLong(pageSize, pageNumber);
    }, [])
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Diagnosis</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Diagnosis</h2>
                                            <div className="item-wrapper">
                                                {
                                                    isFetching && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                }
                                                {
                                                    typeof (LongDiag) !== 'undefined' && LongDiag.length ? (
                                                        <FlatList
                                                            style={{
                                                                flexDirection: 'column', paddingLeft: 10,
                                                                paddingRight: 10,
                                                                paddingBottom: 10
                                                            }}
                                                            showsVerticalScrollIndicator={true}
                                                            data={LongDiag}
                                                            renderItem={({ item }) => (

                                                                <DiagnosisHandler
                                                                    date={GetAppointmentDate(item.resource.onsetDateTime) + ' ' + GetMonthName(item.resource.onsetDateTime)}
                                                                    month={GetYear(item.resource.onsetDateTime)}
                                                                    comments={item.resource.note[0].text}
                                                                />
                                                                // <DiagnosisWebHandler
                                                                //     styles={GetTagColor('Long')}
                                                                //     date={GetAppointmentDate(item.resource.onsetDateTime) + ' ' + GetMonthName(item.resource.onsetDateTime)}
                                                                //     month={GetYear(item.resource.onsetDateTime)}
                                                                //     //type_diagnoes={results.resource.resourceType}
                                                                //     comments={item.resource.note[0].text}
                                                                // />
                                                            )}
                                                            horizontal={false}
                                                            keyExtractor={item => item.resource.id}
                                                            initialNumToRender={10}

                                                        />
                                                    ) : !isFetching && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                                    </View>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                       
                    </footer>
                </div>

            </div>

        </div >
    )
}



function mapStateToProps(state) {
    return {
        LongDiag: state.LongDiag
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPeopleLong: (pageSize, pageNumber) => dispatch(fetchLongTermFromApi(pageSize, pageNumber, 'Not')),
        // getPeopleLongMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreLongTermFromApi(pageSize, pageNumber, 'Not'))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Diagnosis)


//export default Diagnosis

