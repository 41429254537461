import React from 'react';

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text } from "react-native-web";

import { Tabs } from "antd";
import 'antd/dist/antd.css';
import DueTab from './ImmuneTabs/DueTab';
import OverdueTab from './ImmuneTabs/OverdueTab';
import AdministeredTab from './ImmuneTabs/AdministeredTab';
import AllTabs from './ImmuneTabs/AllTabs';
import { fetchProfileFromApi } from '../../shared/actions/ProfileActions';
import { connect } from "react-redux";
import { ActivityIndicator } from 'react-native';

const { TabPane } = Tabs;

export const ImmuneHomeContext = React.createContext();
const initialState = {
    isLoadedTest: true,
    immuneMg: null,

};
const reducer = (state, action) => {
    switch (action.type) {
        // type 
        case "ISIMMUNELOADED":
            // localStorage.setItem("user", JSON.stringify(action.payload.user));
            // localStorage.setItem("token", JSON.stringify(action.payload.token));
            return {
                ...state,
                isLoadedTest: true,
                immuneMg: null,
                // user: action.payload.user,
                // token: action.payload.token
            };
        case "IMMUNELOAD":
            // localStorage.clear();
            return {
                ...state,
                // actions
                isLoadedTest: false,
                immuneMg: action.immuneMg,
                // user: null
            };

        default:
            return state;
    }
};

const ImmuneHome = props => {

    const { Profile, isFetching } = props.Profile;
    const [isloaded, setIsload] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, initialState);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])

    React.useEffect(() => {
        props.getProfile();
    }, [])

    const immuneValue = (value) => {
        console.log("test")
    }

    return (
        <ImmuneHomeContext.Provider value={{
            state,
            dispatch
        }}>
            <div>
                <Header />
                <div id="myphonemenu" className="page-body">
                    <Sidebar />
                    <div className="page-content-wrapper">
                        <div className="page-content-wrapper-inner">
                            <div className="viewport-header">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb has-arrow">
                                        <li className="breadcrumb-item">
                                            <a href="#">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Immunisation</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="content-viewport">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="grid">
                                            <div className="grid-body">
                                                <h2 className="grid-title">Immunisation</h2>
                                                <div className="item-wrapper">
                                                    {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                    {
                                                        typeof Profile !== "undefined" && Profile.length ? (
                                                            // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                                            Profile[0].resource.extension.map((item, index) => {

                                                                return (
                                                                    item.url == "ImmunisationDisclaimer" &&
                                                                    <div class="row mb-2">
                                                                        {isFetching && <ActivityIndicator color='blue' size='small' />}
                                                                        <div class="col-md-8 mx-auto">
                                                                            <div class="alert alert-warning" role="alert">
                                                                                <h6 class="alert-heading">Please Note:</h6>

                                                                                <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                )
                                                            })
                                                        ) : null

                                                    }
                                                    {/* {
                                                                    typeof Profile !== "undefined" && Profile.length ? (
                                                                        <p>{Profile[0].resource.extension[8].valueString}</p>
                                                                    ) :null
                                                                } */}
                                                    {/* {Profile.map((person, i) => {
                                                                    <p>{person.resource.extension[8] !== undefined
                                                                        ? person.resource.extension[8].valueString
                                                                        : 'NO Data Found'}</p>
                                                                })} */}
                                                    {/* <p>   {!isFetching && typeof (Profile[0].resource.extension[8].valueString) !== 'undefined' ? Profile[0].resource.extension[8].valueString : null}</p> */}
                                                    {/* <p>{state.immuneMg}</p> */}
                                                    {/* <p>Please be aware that the vaccination information displayed on this screen relates only to vaccines that were scheduled and/or administered by your treatment centre. Any vaccines administered outside of your treatment centre will not be recorded here</p> */}


                                                    <Tabs defaultActiveKey="1" centered >
                                                        <TabPane tab="Due" key="1" >
                                                            <DueTab />
                                                        </TabPane>
                                                        <TabPane tab="Overdue" key="2">
                                                            <OverdueTab />
                                                        </TabPane>
                                                        <TabPane tab="Administered" key="3">
                                                            <AdministeredTab />
                                                        </TabPane>
                                                        <TabPane tab="All" key="4">
                                                            <AllTabs />
                                                        </TabPane>

                                                    </Tabs>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <footer className="footer">

                        </footer>
                    </div>

                </div>

            </div >
        </ImmuneHomeContext.Provider>
    )
}

function mapStateToProps(state) {
    return {
        Profile: state.Profile,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getProfile:
        getProfile: () => dispatch(fetchProfileFromApi()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImmuneHome);
// export default ImmuneHome
