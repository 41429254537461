import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
import renderHTML from "react-render-html";

const HomeCard = props => {
    return (
        <div className="activity-log">
            <p className="log-name">{props.titleText}</p>
            <div className="log-details">
                {renderHTML(props.textDesc)}</div>
            <small className="log-time">{props.date}</small>
        </div>
    );
}

export default HomeCard