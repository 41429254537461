

import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ReminderHandler = props => {
    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    return (
        <div className="col-lg-12 ">
            <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className='card-header text-white bg-success'>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.reminderDescription}</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.reminderDate}</Text>
                    </View>
                </div>
                <div className="card-body">
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-hospital-building" /> <b>Provider: </b>{props.reminderProvider}</h4>
                    <p className="card-text"><i className="mdi mdi-18px mdi-content-paste" /> <b>Description: </b>{props.reminderDescription}</p>
                    <p className="card-text"><i className="mdi mdi-18px mdi-comment-text" /> <b>Notes: </b>{props.reminderNote}</p>
                </div>
            </div>
        </div>
    );
}

export default ReminderHandler