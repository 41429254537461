import React from 'react';
import { TouchableOpacity } from 'react-native-web';
import { Avatar } from 'antd';
import { getColorForInintials, getInitials } from '../../shared/core/utils';

const Header = props => {
    // const openMenu = () => {
    //     var element = document.getElementById("myphonemenu");
    //     //element.classList.add("sidebar-collpased");
    // }
    return (
        <nav className="t-header">
            <div className="t-header-brand-wrapper">
                <a href="/">
                    <img className="logo" src="https://devnhlapp.indici.ie/Images/mainLogo-indici-140X40.svg" alt="Logo" />
                    <img className="logo-mini" src="https://devnhlapp.indici.ie/Images/mainLogo-indici-140X40.svg" alt="Logo" />
                </a>
                <button className="t-header-toggler t-header-desk-toggler d-none d-lg-block">
                    <svg className="logo" viewBox="0 0 200 200">
                        <path className="top" d="
                            M 40, 80
                            C 40, 80 120, 80 140, 80
                            C180, 80 180, 20  90, 80
                            C 60,100  30,120  30,120
                            " />
                        <path className="middle" d="
                            M 40,100
                            L140,100
                            " />
                        <path className="bottom" d="
                            M 40,120
                            C 40,120 120,120 140,120
                            C180,120 180,180  90,120
                            C 60,100  30, 80  30, 80
                            " />
                    </svg>
                </button>
            </div>
            <div className="t-header-content-wrapper">
                <div className="t-header-content">
                    <button onClick={props.onClick} className="t-header-toggler t-header-mobile-toggler d-block d-lg-none">
                        <i className="mdi mdi-menu" />
                    </button>

                    {/* <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="mdi mdi-magnify" />
                                </div>
                            </div>
                            <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Search" autoComplete="off" />
                        </div> */}

                    <ul className="nav ml-auto">
                        <li className="nav-item dropdown">

                        </li>
                        <li className="nav-item dropdown">

                        </li>
                        <li className="nav-item dropdown">
                            <a class="nav-link" href="#" id="messageDropdown" data-toggle="dropdown" aria-expanded="false">
                                <i class="mdi mdi-36px mdi-help-circle-outline"></i>
                                <span class="notification-indicator notification-indicator-primary notification-indicator-ripple"></span>
                            </a>
                            <div className="dropdown-menu navbar-dropdown dropdown-menu-right" aria-labelledby="messageDropdown">
                                <div className="dropdown-header">
                                    <h6 className="dropdown-title">Help</h6>
                                    <p className="dropdown-title-text">Select Topic to View Help</p>
                                </div>
                                <div className="dropdown-body">
                                    <div className="dropdown-list">
                                        <div className="image-wrapper">
                                            <Avatar style={{ backgroundColor: getColorForInintials() , verticalAlign: 'middle' }} size="large" >
                                                {getInitials("Help") }
                                            </Avatar>
                                            {/* <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" /> */}
                                            {/* <div className="status-indicator rounded-indicator bg-success" /> */}
                                        </div>
                                        <TouchableOpacity onPress={() => window.open('https://www.loom.com/share/1859d7309ed04542a2576e5978ece482?sharedAppSource=personal_library', '_blank')}>
                                            <div className="content-wrapper">
                                                <small className="name">How to use Portal App?</small>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                </div>
                            </div>

                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}


export default Header