import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from "react-redux";
import Dots from "react-activity/lib/Dots";
import 'react-activity/lib/Dots/Dots.css';
import Home from "./pages/Home";


import { View } from "react-native-web";
import Login from "./pages/Login";
import { AuthContext } from "../shared/core/context";
import { validatePatientTokenFromApi } from "../shared/actions/TokenValidActions";
import getUserId from "../shared/core/GetToken";

import Appointments from "./pages/Appointments";
import Diagnosis from "./pages/Diagnosis";
import Med from "./pages/Med";
import PatientResults from "./pages/PatientResults";
import AllergiesHome from "./pages/AllergiesHome";
import ImmuneHome from "./pages/ImmuneHome";
import TimelineHome from './pages/TimelineHome';
import RemidersHome from './pages/RemidersHome';
import ResourcesHome from './pages/ResourcesHome';
import ForgetPassword from "./pages/ForgetPassword";
import LettersDocs from "./pages/LettersDocs";
import Vitals from "./pages/Vitals";
import ResetPassword from './pages/ResetPassword';
import Messaing from "./pages/Messaing";
import UserProfile from "./pages/UserProfile";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import OuterHtml from './components/OutterHtml';
// import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import MyCareTeam from './pages/MyCareTeam';
import ReadMessage from './components/ReadMessage'


import ReactGA from "react-ga4";
// import ReactGA from 'react-ga';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyBVlxD9uptxI7bHJbLPIQqCbA1ypc8jdfg",
//     authDomain: "myindici-ireland.firebaseapp.com",
//     projectId: "myindici-ireland",
//     storageBucket: "myindici-ireland.appspot.com",
//     messagingSenderId: "91561741352",
//     appId: "1:91561741352:web:21b94d5514a6f76cd4791d",
//     measurementId: "G-NGMTNSJ7E4"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const firebaseAnalytics = getAnalytics(app);


const Route = require("react-router-dom").Route;
const Switch = require("react-router-dom").Switch;
const Router = require("react-router-dom").BrowserRouter;

const Redirect = require("react-router-dom").Redirect;
const useHistory = require("react-router-dom").useHistory;
const loadjs = require('loadjs');



//function Main() {
// const GA_MEASUREMENT_ID = 'G-NGMTNSJ7E4';

// ReactGA.initialize(GA_MEASUREMENT_ID);

ReactGA.initialize("G-MMF2HFV8XR");


const Main = props => {
    // useGoogleAnalytics();
    const [userToken, setUserToken] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const { TokenValidator, isChecking } = props.TokenValidator;
    const linkHistory = useHistory();

    // React.useEffect(() => {
    //     loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
    //     loadjs.ready('foobar', function () {

    //     });
    // }, [])

    useEffect(() => {
        // ReactGA.pageview(window.location.pathname);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "indici Patient Portal" });


    }, [window.location.pathname]);

    React.useEffect(() => {
        getUserId().then((data) => {
            if (data != 'none') {
                props.validateToken();
            }
        })
    }, []);
    //context api
    const authContext = React.useMemo(() => {
        return {
            signIn: () => {
                setUserToken("asdf");
            },
            signOut: () => {
                setUserToken(null);
                // location.reload();
            },
        }
    })


    return (

        <AuthContext.Provider value={authContext}>
            {/* <Switch>
                <Route exact path="/" component={Login} />
            </Switch> */}


            {
                isChecking &&
                <View style={{
                    opacity: 1,
                    position: 'relative',
                    backgroundColor: '#fff',
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Dots />
                </View>

            }
            {
                !isChecking &&
                <Switch>


                    <PublicRoute exact path="/login" component={Login} authed={TokenValidator} />
                    <PublicRoute exact path="/forgetPassword" component={ForgetPassword} authed={TokenValidator} />
                    <PublicRoute exact path="/resetpassword" component={ResetPassword} authed={TokenValidator} />

                    <PrivateRoute exact path="/preview" component={OuterHtml} authed={TokenValidator} />



                    <PrivateRoute exact path="/" component={Home} authed={TokenValidator} />
                    <PrivateRoute exact path="/appointments" component={Appointments} authed={TokenValidator} />
                    <PrivateRoute exact path="/diagnosis" component={Diagnosis} authed={TokenValidator} />


                    <PrivateRoute exact path="/medications" component={Med} authed={TokenValidator} />
                    <PrivateRoute exact path="/reports" component={PatientResults} authed={TokenValidator} />
                    <PrivateRoute exact path="/allergies" component={AllergiesHome} authed={TokenValidator} />
                    <PrivateRoute exact path="/immunisation" component={ImmuneHome} authed={TokenValidator} />
                    <PrivateRoute exact path="/timeline" component={TimelineHome} authed={TokenValidator} />
                    <PrivateRoute exact path="/reminders" component={RemidersHome} authed={TokenValidator} />
                    <PrivateRoute exact path="/resources" component={ResourcesHome} authed={TokenValidator} />
                    <PrivateRoute exact path="/letters" component={LettersDocs} authed={TokenValidator} />
                    <PrivateRoute exact path="/vitals" component={Vitals} authed={TokenValidator} />
                    <PrivateRoute exact path="/emails" component={Messaing} authed={TokenValidator} />
                    <PrivateRoute exact path="/profile" component={UserProfile} authed={TokenValidator} />
                    <PrivateRoute exact path="/myCareTeam" component={MyCareTeam} authed={TokenValidator} />

                    <PrivateRoute exact path="/read" component={ReadMessage} authed={TokenValidator} />


                    {/* <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute exact path="/appointments" component={Appointments} /> */}
                    {/* <PrivateRoute exact path="/diagnosis" component={Diagnosis} />
                        */}

                </Switch>



            }

            {/* {
                isChecking &&
                <View style={{
                    opacity: 1,
                    position: 'relative',
                    backgroundColor: '#fff',
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Dots />
                </View>

            }
            {
                !isChecking && userToken != null || TokenValidator ? (
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/appointments" component={Appointments} />
                        <Route exact path="/diagnosis" component={Diagnosis} />
                        <Route exact path="/medications" component={Med} />
                        <Route exact path="/reports" component={PatientResults} />
                        <Route exact path="/allergies" component={AllergiesHome} />
                        <Route exact path="/immunisation" component={ImmuneHome} />
                        <Route exact path="/timeline" component={TimelineHome} />
                        <Route exact path="/reminders" component={RemidersHome} />
                        <Route exact path="/resources" component={ResourcesHome} />
                        <Route exact path="/letters" component={LettersDocs} />
                        <Route exact path="/vitals" component={Vitals} />
                        <Route exact path="/emails" component={Messaing} />
                        <Route exact path="/profile" component={UserProfile} />

                    </Switch>
                ) : (
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/forgetPassword" component={ForgetPassword} />
                            <Route exact path="/resetpassword" component={ResetPassword} />
                        </Switch>
                    )
            } */}

            {/* <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/appointments" component={Appointments} />
            </Switch> */}
        </AuthContext.Provider >
    );
}




function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator,

    }
}
function mapDispatchToProps(disptach) {
    return {
        validateToken: () => disptach(validatePatientTokenFromApi()),

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)
