import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const MedicationHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={'card-header text-white bg-danger'}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.medicationDetails}</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.medDate}</Text>
                    </View>
                </div>
                <div className="card-body">
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-pharmacy" /> {props.directions}</h4>
                    <p style={{ marginTop: 5 }} className="card-text"><i className="mdi mdi-18px mdi-calendar-clock" /> Period: {props.checking} , Quantity: {props.quantity}</p>

                    <View style={{ width: '100%', marginTop: 0, borderTopWidth: 1, borderTopColor: '#DDDDDD' }}></View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p className="card-text"><i className="mdi mdi-24px mdi-clock-in" />Start Date: {props.startDate}</p>
                        <p className="card-text"><i className="mdi mdi-24px mdi-clock-out" />End Date: {props.endDate}</p>
                    </View>

                </div>
            </div>
        </div>
    );
}

export default MedicationHandler
