


import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const TreatmentHandler = props => {

    const getRandomTag = () => {
        var textArray = [
            // 'card-header text-white bg-danger',
            // 'card-header text-white bg-success',
            'card-header text-white bg-primary',
            // 'card-header text-white bg-info',
            // 'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        //  return textArray[randomNumber]

        return 'card-header text-white bg-primary'
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    return (
        <div className="col-lg-12 ">
            <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className='card-header text-white bg-primary'>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.type_diagnoes}</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.date}</Text>
                    </View>
                </div>
                <div className="card-body">
                    {/* <h4 className="card-title"><i className="mdi mdi-18px mdi-hospital" /> {props.comments}</h4> */}
                    <div class="card">
                        <h4 className="card-title"><i className="mdi mdi-18px bg- mdi-water" /> {props.comments}</h4>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TreatmentHandler
