import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, ActivityIndicator, Switch } from "react-native-web";
import ProfileItem from '../components/ProfileItem';
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions";
import { fetchPractisefromAPI } from "../../shared/actions/PractiseAction";
import { GetAppointmentDate, GetMonthName, GetYear, capitalize } from '../../shared/core/datehelper'
import ProfileItemB from '../components/ProfileItemB';
import ProfileItemSingle from '../components/ProfileItemSingle';
import getUserId from '../../shared/core/GetToken';
import { TouchableOpacity } from 'react-native';
import { Modal, Button } from "react-bootstrap";
import changePasswrodService from '../../shared/services/ChangePasswrod';
import { passwordValidator } from '../../shared/core/utils';
import AsyncStorage from "@react-native-async-storage/async-storage";
import getUserInfo from '../../shared/core/GetTokenized';
import Settings from '../../shared/config/Settings';

// import "../components/css/profile.css"


const UserProfile = props => {

    const [showReset, setShowReset] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [passwordStartMessage, setPasswordStartMessage] = useState({
        error: false,
        message: ''
    })

    const [isloading, setIsLoading] = useState(false);
    const [isloadingNot, setIsLoadingNot] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const toggleSwitch = (emailVal, pushVal, tokenVal) => {
        console.log("EMail ==>" + emailVal, "push ==>" + pushVal, "Tok ==>" + tokenVal)
        //alert(emailVal)
        setIsLoading(true);
        emailVal
            ? callApi(false, pushVal, tokenVal)
            : callApi(true, pushVal, tokenVal);
    };



    const toggleSwitchPushNotes = (emailVal, pushVal, tokenVal) => {
        setIsLoadingNot(true);
        pushVal
            ? callApi(emailVal, false, tokenVal)
            : callApi(emailVal, true, tokenVal);
    };



    const handleCloseReset = () => {
        setShowReset(false)
        // testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };
    const resetPasswordFunc = async () => {

        let password = document.getElementById("password").value;
        let confirmPassword = document.getElementById("confirmPassword").value;

        const passwordError = passwordValidator(password);
        const cnfrmPaswrd = passwordValidator(confirmPassword);
        if (passwordError) {
            document.getElementById("errorMessageReset").innerHTML = "Password cannot be empty "
            return;
        }
        if (cnfrmPaswrd) {
            document.getElementById("errorMessageReset").innerHTML = "Confirm Password cannot be empty "
            return;
        }
        if (password !== confirmPassword) {
            document.getElementById("errorMessageReset").innerHTML = "Passwords did not matched. Please try again"
            return
        }
        //check for lower case
        if (!password.match(/[a-z]/)) {
            alert("Password must contain at least one lower case letter.");
            // passForm.passInput.focus();
            return;
        }

        //Validating length
        if (password.length < 8) {
            alert("Your password has less than 8 characters.");
            return;
        }

        //check for upper ase
        if (!password.match(/[A-Z]/)) {
            alert("Password must contain at least one upper case letter.");
            // passForm.passInput.focus();
            return;
        }
        var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
        //check for number
        if (!patt.test(password)) {
            alert("Password must contain at least two number.");
            // passForm.passInput.focus();
            return;
        }
        setIsResetPassword(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token;
            console.log("ResetPasswrod===>" + password)

            var jsonPa = JSON.stringify({ "PatientID": item.parentid, "Password": password })
            changePasswrodService(queryString, jsonPa).then(data => data.json())
                .then(json => {
                    console.log(json)
                    // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
                    if (json.StatusCode == "1") {
                        // for activity indicatorr
                        setIsResetPassword(false)
                        setPasswordStartMessage(prevState => ({ ...prevState, error: false, message: json.StatusMessage }))
                        alert(json.StatusMessage)
                        handleCloseReset()

                        console.log("statscode==1==>" + JSON.stringify(json))
                        // handleCloseReset()
                    }
                    // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
                    if (json.StatusCode == "3") {
                        setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: json.StatusMessage }))
                        setIsResetPassword(false)
                        console.log("statscode==2==>" + JSON.stringify(json))

                    }
                })
                .catch(err => {
                    setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: "Some thing went wrong" }))

                    console.log("resetPasswrodError==>" + err)
                })
        })
        // check remining confirm and passweordk

    }
    const callApi = (mail, push, tok) => {

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var raw = JSON.stringify({
                resourceType: "Patient",
                id: item.userid,
                extension: [
                    {
                        url: "PatientDeviceToken",
                        valueString: tok,
                    },
                    {
                        url: "EnableEmail",
                        valueBoolean: mail,
                    },
                    {
                        url: "EnableNotification",
                        valueBoolean: push,
                    },
                ],
            });

            // alert(isEnabled)

            var requestOptions = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow",
            };
            console.log("raw", raw);

            //alert(raw)
            fetch(
                Settings.apiUrl + "Patient?Token=" + item.token + "",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    setIsLoading(false);
                    setIsLoadingNot(false);
                    console.log("result", result);
                    props.getProfile();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsLoadingNot(false);
                    console.log("error", error);
                });

            // console.log(token);
        });
    };


    const addressNodes = ['Address Line 1', 'Address Line 2', 'Address Line 3', 'City/County', 'Country', 'Eircode']

    const { Profile, isFetching } = props.Profile;
    const { Practise } = props.Practise;
    const [isloaded, setIsload] = React.useState(false);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])



    useEffect(() => {
        props.getProfile();
        props.getPracticeCenterDetail()

        // props.getProxy();
    }, []);


    const getByTitle = item => {

        if (item.system === "email" && item.use === "home") {
            return "Primary Email"
        }
        else if (item.system === "email" && item.use === "temp") {
            return "Secondary Email"
        }
        else {
            return capitalize(item.use)
        }
        //  item.system === "email" && item.use ==="home" ?   capitalize(item.use)
    }


    const [showincon, setshowicon] = useState('mdi mdi-eye')
    const showPassword = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }


    const showPasswordConfirm = () => {
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }

    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div class="row mb-2">
                                        <div class="col-md-8 mx-auto">
                                            {/* <div class="alert alert-warning" role="alert">
                                                <h6 class="alert-heading">Please Note:</h6> */}
                                            {/* <p>
                                                    {
                                                        isFetching && <ActivityIndicator size="small" />
                                                    }
                                                    {!isFetching && typeof (Profile[0].resource.extension[7].valueString) !== 'undefined' ? Profile[0].resource.extension[7].valueString : null}
                                                </p> */}
                                            <p>
                                                {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                {
                                                    typeof Profile !== "undefined" && Profile.length ? (
                                                        // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                                        Profile[0].resource.extension.map((item, index) => {

                                                            return (
                                                                item.url == "PatientProfileDisclaimer" &&
                                                                <div class="row mb-2">

                                                                    <div class="col-md-12 mx-auto">

                                                                        <div class="alert alert-warning" role="alert">
                                                                            {isFetching && <ActivityIndicator color='blue' size='small' />}
                                                                            <h6 class="alert-heading">Please Note:</h6>

                                                                            <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : null

                                                }
                                                {/* {
                                                        typeof Profile !== "undefined" && Profile.length ? (
                                                            <p>{Profile[0].resource.extension[7].valueString}</p>
                                                        ) : null
                                                    } */}
                                            </p>

                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <p className="grid-header">Personal Information</p>

                                        </div>


                                        <div className="grid-body">
                                            <div className="item-wrapper">

                                                <div className="row">
                                                    <div className="col-md-8 mx-auto">
                                                        <View style={{ height: 30 }}>
                                                            {
                                                                isFetching && <ActivityIndicator size="small" />
                                                            }
                                                        </View>
                                                        {
                                                            typeof (Profile) !== 'undefined' && Profile.length ? (
                                                                <ProfileItem
                                                                    title_A="Title"
                                                                    value_A={typeof (Profile[0].resource.name[0].prefix) !== 'undefined' ? Profile[0].resource.name[0].prefix[0] : null}
                                                                    title_B="Name"
                                                                    value_B={typeof (Profile[0].resource.name) !== 'undefined' ? Profile[0].resource.name[0].given[0] : null}
                                                                />


                                                            ) : null
                                                        }


                                                        {
                                                            typeof (Profile) !== 'undefined' && Profile.length ? (
                                                                <ProfileItem
                                                                    title_A="Family Name"
                                                                    value_A={typeof (Profile[0].resource.name[0].family) !== 'undefined' ? Profile[0].resource.name[0].family : null}
                                                                    title_B="Preferred  Name"
                                                                    value_B=""
                                                                />


                                                            ) : null
                                                        }


                                                        {
                                                            typeof (Profile) !== 'undefined' && Profile.length ? (
                                                                <ProfileItem
                                                                    title_A="Gender"
                                                                    value_A={typeof (Profile[0].resource.gender) !== 'undefined' ? capitalize(Profile[0].resource.gender) : null}
                                                                    title_B="DOB"
                                                                    value_B={typeof (Profile[0].resource.birthDate) !== 'undefined' ? GetAppointmentDate(Profile[0].resource.birthDate) + '-' + GetMonthName(Profile[0].resource.birthDate) + '-' + GetYear(Profile[0].resource.birthDate) : null}
                                                                />


                                                            ) : null
                                                        }







                                                        {
                                                            typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.extension.map((value, index) => {
                                                                if (value.url == "Ethnicity") {
                                                                    return < ProfileItem
                                                                        title_A="Ethnicity"
                                                                        value_A={value.valueString}
                                                                        title_B=""
                                                                        value_B=""
                                                                    />
                                                                }

                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid">
                                        <p className="grid-header">Address Information</p>
                                        <div className="grid-body">
                                            <div className="item-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12 mx-auto">
                                                        {/* {
                                                            isFetching && <ActivityIndicator size="small" />
                                                        } */}

                                                        {/* {
                                                            typeof (Profile) !== 'undefined' && Profile.length ? (
                                                                <ProfileItemB title="Primary Address" address={Profile[0].resource.address[0].text + ', ' + Profile[0].resource.address[0].country} />

                                                            ) : null
                                                        } */}

                                                        {
                                                            typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.address !== undefined ? Profile[0].resource.address[0].line.map((item, index) => {
                                                                return (

                                                                    <ProfileItemB title={addressNodes[index]} address={item} />

                                                                )
                                                            }) : <p>No address found.</p>
                                                        }



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid">
                                        <p className="grid-header">Contact Information</p>
                                        <div className="grid-body">
                                            <div className="item-wrapper">
                                                <div className="row mb-3">
                                                    <div className="col-md-8 mx-auto">
                                                        {/* title goe here */}



                                                        {
                                                            typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.telecom.map((item) => {
                                                                return (

                                                                    <ProfileItemSingle contactTile={getByTitle(item)} contactValue={item.value} />

                                                                )
                                                            })
                                                        }

                                                        {/* {Profile[0].resource.telecom.map((value, index) => {

                                                            console.log(JSON.stringify(value.use))
                                                            if (value.use == "home" && value.use == "work")
                                                                return <ProfileItemSingle contactTile="Work Phone Number: " contactValue={value.value} /> //<Text style={[innerStyle.personDetailText, { fontWeight: 'bold' }]} key={index}>Work Phone Number:</Text>

                                                            // verified form sir haseeb that weather it is relative to cell phone number
                                                            if (value.use == "mobile" && value.system == "phone")
                                                                return <ProfileItemSingle contactTile="Cell Phone Number: " contactValue={value.value} />// <Text style={[innerStyle.personDetailText, { fontWeight: 'bold' }]} key={index}>Cell Phone Number:</Text>


                                                        })} */}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid">
                                        <p className="grid-header">Centre Details</p>
                                        <div className="grid-body">
                                            <div className="item-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12 mx-auto">
                                                        {/* {
                                                            isFetching && <ActivityIndicator size="small" />
                                                        } */}


                                                        {/* {
                                                            typeof (Practise) !== 'undefined' && Practise.length ? (
                                                                Practise.map((p, i) => {

                                                                    return <ProfileItemB title="Center Name" address={p.resource.extension[4] !== undefined ? p.resource.extension[4].valueString : 'N/A'} />
                                                                })
                                                            ) : <ProfileItemB title="Center Name" address={'N/A'} />

                                                        } */}
                                                        {/* {

                                                            typeof (Practise) !== 'undefined' && Practise.length ? (
                                                                // console.log(Practise[0].resource.extension[3])
                                                                Practise[0].resource.extension.map((person, i) => {
                                                                    console.log(person.url === 'Practice Location' ? person.valueString : 'N/A')// === 'Practice Location' ? person.resource.extension[i].valueString : console.log('N/A'))
                                                                    if (person.url === 'Practice Location') {
                                                                        return <ProfileItemB title="Centre Name" address={person.url === 'Practice Location' ? person.valueString : 'N/A'} />
                                                                        // return <ProfileInfoList key={i} title="Center Name" profileData={person.url === 'Practice Location' ? person.valueString : 'N/A'} />//{person.resource.extension[4] !== undefined ? person.resource.extension[4].valueString : 'N/A'} />
                                                                    }
                                                                })
                                                            ) : <ProfileItemB title="Centre Name" address={'N/A'} />

                                                        } */}

                                                        <ProfileItemB title="Centre Name" address={Profile[0]?.resource?.extension[17]?.valueString ?Profile[0]?.resource?.extension[17]?.valueString :'N/A'} />

                                                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>

                                                            <View style={{ marginTop: 10, width: '40%', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                <View style={{ flexDirection: 'column' }}>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>National Coagulation Centre:</Text>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Children’s Health Ireland:</Text>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>Cork University Hospital:</Text>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}>University Hospital Galway:</Text>
                                                                </View>
                                                                <View style={{ flexDirection: 'column' }}>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}><a target="_blank" href="https://www.stjames.ie/services/hope/nationalcoagulationcentre/">Visit</a></Text>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}><a href="https://www.olchc.ie/" target="_blank">Visit</a></Text>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}><a target="_blank" href="https://haemophilia.ie/about-haemophilia/treatment-centres-in-ireland/cork-university-hospital-treatment-centre/">Visit</a></Text>
                                                                    <Text style={{ fontWeight: 'bold', marginTop: 10 }}><a target="_blank" href="https://www.saolta.ie/hospital/university-hospital-galway">Visit</a></Text>
                                                                </View>
                                                            </View>

                                                        </View>





                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid">
                                        <p id="noti" className="grid-header">Notification Settings</p>
                                        <div className="grid-body">
                                            <div className="item-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12 mx-auto">
                                                        {/* {
                                                            isFetching && <ActivityIndicator size="small" />
                                                        } */}
                                                        <View style={{
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            padding: 10,
                                                            backgroundColor: "#fff",
                                                            borderBottomWidth: 1,
                                                            borderBottomColor: "#DDDDDD",
                                                            marginTop: 10,
                                                        }}>
                                                            <Text style={{ flex: 1, flexWrap: "wrap" }}>Enable Email Notifications: </Text>


                                                            {Profile.map((person, i) => {
                                                                console.log("THe lenght is===>>>>>>" + person.resource.extension.length)
                                                                return (
                                                                    <>
                                                                        {
                                                                            isloading && <ActivityIndicator style={{ paddingRight: 10 }} size="small" />
                                                                        }
                                                                        <Switch
                                                                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                                                                            thumbColor={true ? "#fff" : "#f4f3f4"}
                                                                            ios_backgroundColor="#3e3e3e"
                                                                            onValueChange={() =>


                                                                                person.resource.extension.length > 6 ? (
                                                                                    toggleSwitch(
                                                                                        person.resource.extension[5] !== undefined
                                                                                            ? person.resource.extension[5].valueBoolean
                                                                                            : false,
                                                                                        person.resource.extension[6] !== undefined
                                                                                            ? person.resource.extension[6].valueBoolean
                                                                                            : false,
                                                                                        person.resource.extension[4] !== undefined
                                                                                            ? person.resource.extension[4].valueString
                                                                                            : 'N/A'
                                                                                    )
                                                                                ) : toggleSwitch(
                                                                                    person.resource.extension[4] !== undefined
                                                                                        ? person.resource.extension[4].valueBoolean
                                                                                        : false,
                                                                                    person.resource.extension[5] !== undefined
                                                                                        ? person.resource.extension[5].valueBoolean
                                                                                        : false,
                                                                                    person.resource.extension[3] !== undefined
                                                                                        ? person.resource.extension[3].valueString
                                                                                        : 'N/A'
                                                                                )

                                                                            }
                                                                            value={
                                                                                person.resource.extension.length > 6 ? (
                                                                                    person.resource.extension[5] !== undefined
                                                                                        ? person.resource.extension[5].valueBoolean
                                                                                        : false
                                                                                ) : (
                                                                                    person.resource.extension[4] !== undefined
                                                                                        ? person.resource.extension[4].valueBoolean
                                                                                        : false
                                                                                )


                                                                            }
                                                                            disabled={isloading}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </View>


                                                        <View style={{
                                                            width: "100%",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            padding: 10,
                                                            backgroundColor: "#fff",
                                                            borderBottomWidth: 1,
                                                            borderBottomColor: "#DDDDDD",
                                                            marginTop: 10,
                                                        }}>
                                                            <Text style={{ flex: 1, flexWrap: "wrap" }}>Enable In-App Notifications: </Text>


                                                            {Profile.map((person, i) => {
                                                                console.log("THe lenght is===>>>>>>" + person.resource.extension.length)
                                                                return (
                                                                    <>
                                                                        {
                                                                            isloadingNot && <ActivityIndicator style={{ paddingRight: 10 }} size="small" />
                                                                        }
                                                                        <Switch
                                                                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                                                                            thumbColor={true ? "#fff" : "#f4f3f4"}
                                                                            ios_backgroundColor="#3e3e3e"
                                                                            onValueChange={() =>


                                                                                person.resource.extension.length > 6 ? (
                                                                                    toggleSwitchPushNotes(
                                                                                        person.resource.extension[5] !== undefined
                                                                                            ? person.resource.extension[5].valueBoolean
                                                                                            : false,
                                                                                        person.resource.extension[6] !== undefined
                                                                                            ? person.resource.extension[6].valueBoolean
                                                                                            : false,
                                                                                        person.resource.extension[4] !== undefined
                                                                                            ? person.resource.extension[4].valueString
                                                                                            : 'N/A'
                                                                                    )
                                                                                ) : toggleSwitchPushNotes(
                                                                                    person.resource.extension[4] !== undefined
                                                                                        ? person.resource.extension[4].valueBoolean
                                                                                        : false,
                                                                                    person.resource.extension[5] !== undefined
                                                                                        ? person.resource.extension[5].valueBoolean
                                                                                        : false,
                                                                                    person.resource.extension[3] !== undefined
                                                                                        ? person.resource.extension[3].valueString
                                                                                        : 'N/A'
                                                                                )

                                                                            }
                                                                            value={
                                                                                person.resource.extension.length > 6 ? (
                                                                                    person.resource.extension[6] !== undefined
                                                                                        ? person.resource.extension[6].valueBoolean
                                                                                        : false
                                                                                ) : (
                                                                                    person.resource.extension[5] !== undefined
                                                                                        ? person.resource.extension[5].valueBoolean
                                                                                        : false
                                                                                )


                                                                            }
                                                                            disabled={isloadingNot}
                                                                        />
                                                                    </>
                                                                );
                                                            })}
                                                        </View>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid">
                                        <p className="grid-header">Update Password</p>
                                        <div className="grid-body">
                                            <div className="item-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12 mx-auto">
                                                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>

                                                            <View style={{ marginTop: 10, width: '40%', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                <View style={{ flexDirection: 'column' }}>
                                                                    <TouchableOpacity onPress={() => { setShowReset(true) }}>
                                                                        <button className="btn btn-primary btn-block"> Update My Password </button>
                                                                    </TouchableOpacity>

                                                                </View>
                                                            </View>

                                                        </View>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="grid">
                                        <p className="grid-header">Term and condition</p>
                                        <div className="grid-body">
                                            <div className="item-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12 mx-auto">
                                                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>

                                                            <View style={{ marginTop: 10, width: '40%', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                                {/* <View style={{ flexDirection: 'column' }}> */}
                                                                <TouchableOpacity onPress={() => {
                                                                    const win = window.open("https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2Fmyindici%20Patient%20Portal%20User%20Agreement.pdf?alt=media&token=10d36f21-f4fe-4364-8205-82f5c2448c3f", '_blank');
                                                                    if (win != null) {
                                                                        win.focus();
                                                                    }
                                                                }}>
                                                                    <button className="btn btn-primary btn-block">View Terms & Conditions</button>
                                                                </TouchableOpacity>
                                                                <TouchableOpacity onPress={() => {
                                                                    const win = window.open("https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2Fmyindici%20Patient%20Portal%20%20Privacy%20Statement.pdf?alt=media&token=85224819-02cc-4bc9-93b3-db304e31f1ae", '_blank');
                                                                    if (win != null) {
                                                                        win.focus();
                                                                    }
                                                                }}>
                                                                    <button className="btn btn-primary btn-block">View Privacy Policy</button>
                                                                </TouchableOpacity>

                                                                {/* </View> */}
                                                            </View>

                                                        </View>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

                <Modal show={showReset} onHide={() => handleCloseReset()} backdrop="static"
                    keyboard={false}>
                    <Modal.Header className="modal-header bg-primary" closeButton>
                        <Modal.Title className="modal-title text-white">Update your password to continue.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div>
                            <View style={{ backgroundColor: "#d1ecf1", padding: 10, marginBottom: 5 }}>
                                <Text style={{ fontWeight: 'bold' }}>Password Rules</Text>
                                <Text style={{}}>Password must be at least 8 characters.</Text>
                                <Text style={{}}>Password must contain at least one alphabet and two digits.</Text>
                                <Text style={{}}>Password must not contain first, middle or family name.</Text>
                                <Text style={{}}>Example: abc12345 or Abc@1234</Text>
                            </View>
                            {/* <div className="form-group input-rounded">
                                <input id="password" type="text" className="form-control" placeholder="Enter password." />
                            </div>
                            <div className="form-group input-rounded">
                                <input id="confirmPassword" type="text" className="form-control" placeholder="Confirm password." />
                            </div>
                            <div className="form-group input-rounded">
                                <p id="errorMessageReset" style={{ color: "red" }}></p>
                            </div> */}

                            <div className="form-group">
                                <div className="input-group">
                                    <input id="password" type="password" className="form-control" placeholder="Password" />
                                    <div className="input-group-append">
                                        <div onClick={() => showPassword()} className="input-group-text"><i className={showincon}></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <input id="confirmPassword" type="password" className="form-control" placeholder="Password" />
                                    <div className="input-group-append">
                                        <div onClick={() => showPasswordConfirm()} className="input-group-text"><i className={showincon}></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group input-rounded">
                                <p id="errorMessageReset" style={{ color: "red" }}></p>
                            </div>
                            {
                                isResetPassword && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                            }
                            {
                                passwordStartMessage.error && <View style={{ textAlign: 'center', marginBottom: 5 }}><Text style={{ color: "red" }}>{passwordStartMessage.message}</Text></View>
                            }
                            <button onClick={() => resetPasswordFunc()} className="btn btn-primary btn-block">Set Password </button>

                        </div>
                    </Modal.Body>

                </Modal>

            </div>

        </div >
    )
}

function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        Practise: state.Practise
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getProfile:
        getProfile: () => dispatch(fetchProfileFromApi()),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
