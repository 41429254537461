import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { getformatedDate } from "../../shared/core/datehelper";
import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import LetterHandler from '../components/LetterHandler';
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime } from '../../shared/core/datehelper';
import { fetchLettersFromApi, searchLettersFromApi } from "../../shared/actions/LettersActions";
import getUserId from "../../shared/core/GetToken";
import { fetchProfileFromApi } from '../../shared/actions/ProfileActions';


import { DatePicker } from "antd";
import 'antd/dist/antd.css';
const { RangePicker } = DatePicker;

const LettersDocs = props => {
    const { Profile, isFetching } = props.Profile;
    const [isloaded, setIsload] = React.useState(false);
    const { Letters, isGettingLetters } = props.Letters
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])

    React.useEffect(() => {
        props.getProfile();
    }, [])

    useEffect(() => {
        props.getLetters(pageSize, pageNumber);
    }, [])





    const dateFormat = 'DD-MM-YYYY';
    const dateChange = (date, dateString) => {
        if (date === null) {
            props.getLetters(pageSize, pageNumber);
        }
        else {

            var str = dateString.toString();

            str = str.split(",");

            // props.searchLetters(JSON.stringify(date[0]).replace(/['"]+/g, ''), JSON.stringify(date[1]).replace(/['"]+/g, ''))
            // alert(getformatedDate(str[0]) + " 00:00:00" + ",," + getformatedDate(str[1]) + " 23:59:59")
            //

            props.searchLetters(getformatedDate(str[0]) + " 00:00:00", getformatedDate(str[1]) + " 23:59:59")

        }

    }
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Letter & Documents</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                          
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Letter & Documents</h2>
                                            <div className="item-wrapper">
                                                {/* <div class="row mb-2">
                                                    <div class="col-md-8 mx-auto">
                                                        <div class="alert alert-warning" role="alert">
                                                            <h6 class="alert-heading">Please Note:</h6> */}

                                                {
                                                    typeof Profile !== "undefined" && Profile.length ? (
                                                        // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                                        Profile[0].resource.extension.map((item, index) => {
                                                            return (
                                                                item.url == "LetterDisclaimer" &&
                                                                <div class="row mb-2">

                                                                    <div class="col-md-8 mx-auto">
                                                                        <div class="alert alert-warning" role="alert">
                                                                            <h6 class="alert-heading">Please Note:</h6>

                                                                            <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                                            {isFetching && <ActivityIndicator color='blue' size='small' />}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                    ) : null

                                                }
                                                {/* {Profile.map((person, i) => {
                                                                    <p>{person.resource.extension[8] !== undefined
                                                                        ? person.resource.extension[8].valueString
                                                                        : 'NO Data Found'}</p>
                                                                })} */}
                                                {/* <p>   {!isFetching && typeof (Profile[0].resource.extension[8].valueString) !== 'undefined' ? Profile[0].resource.extension[8].valueString : null}</p> */}
                                                {/* <p>{state.immuneMg}</p> */}
                                                {/* <p>Please be aware that the vaccination information displayed on this screen relates only to vaccines that were scheduled and/or administered by your treatment centre. Any vaccines administered outside of your treatment centre will not be recorded here</p> */}
                                                {/* </div>
                                                    </div>
                                                </div> */}

                                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                                                    <Text>Select Date to Search </Text>
                                                    <RangePicker format={dateFormat} onChange={dateChange} />
                                                </View>
                                                <div className="drive-wrapper drive-grid-view">
                                                    <div className="grid-items-wrapper">
                                                        {
                                                            isGettingLetters && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                        }
                                                        {
                                                            typeof (Letters) !== 'undefined' && Letters.length ? (
                                                                Letters.map((item) => {
                                                                    return (
                                                                        <LetterHandler
                                                                            letterDate={GetAppointmentDate(item.resource.extension[1].valueDateTime) + ' ' + GetMonthName(item.resource.extension[1].valueDateTime) + ' ' + GetYear(item.resource.extension[1].valueDateTime)}
                                                                            letterDetail={item.resource.type.coding[0].display}
                                                                            letterId={item.resource.id}
                                                                        />
                                                                    )
                                                                })
                                                            ) : !isGettingLetters && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                                <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                                            </View>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}


function mapStateToProps(state) {
    return {
        Letters: state.Letters,
        Profile: state.Profile,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getProfile: () => disptach(fetchProfileFromApi()),
        getLetters: (pageSize, pageNumber) => disptach(fetchLettersFromApi(pageSize, pageNumber)),
        searchLetters: (fromDate, toDate) => disptach(searchLettersFromApi(fromDate, toDate)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LettersDocs)




