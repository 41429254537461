import React, { useEffect } from 'react'
import getUserId from '../../shared/core/GetToken'
import { Chart, Line } from 'react-chartjs-2';

export default function VitalHandler({
    vitalName,
    vitalLabelsArray,
    vitalDataArray
}) {


    useEffect(() => {
        // console.log("labels", JSON.stringify(vitalLabelsArray))
        let key = document.getElementById(vitalName).getContext('2d');
        var myChart = new Chart(key, {
            type: 'line',
            data: {
                labels: vitalLabelsArray,
                datasets: [{
                    label: vitalName,
                    data: vitalDataArray,
                    backgroundColor: [
                        'rgba(0, 0, 0, 0.1)'
                    ],
                    pointBackgroundColor: [
                        'rgba(0, 0, 0, 0.1)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                tooltips: {
                    mode: 'nearest'
                }
            }
        });
    }, [])




    return (
        <div className="col-md-6" >
            <div className="grid">
                <div className="grid-body">
                    <h2 className="grid-title">{vitalName}</h2>
                    <div className="item-wrapper">
                        <canvas id={vitalName}></canvas>
                    </div>
                </div>
            </div>
        </div>
    )
}
