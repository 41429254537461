import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


//export default function AppointmentHandler() {
const AppointmentHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.styleName}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.appointmentDate}</Text>
                        {/* <Text style={{ fontWeight: '500', color: '#fff' }}>{props.firstitem}</Text> */}
                    </View>
                </div>
                <div className="card-body">
                    {/* <h4 className="card-title"><i className="mdi mdi-18px mdi-home-map-marker" /> {props.praticeAddress}</h4> */}
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-home-map-marker" /> {props.firstitem}</h4>
                    <p className="card-text"><i style={{ marginRight: 4 }} className="mdi mdi-18px mdi-calendar-clock" />{props.secondItem}</p>

                    <p className="card-text"><i className="mdi mdi-18px mdi-hospital-marker" />{props.location}</p>

                    {
                        props.reason !== undefined && <div>
                            <View style={{ width: '100%', marginTop: 0, borderTopWidth: 1, borderTopColor: '#DDDDDD' }}></View>
                            <p className="card-text"><i style={{ marginRight: 4 }} className={props.icTpye} />{props.reason}</p>
                            {
                                props.reasonForCancel !== null && <p className="card-text"><i style={{ marginRight: 4 }} className={props.icTpyeCancel} />{props.reasonForCancel}</p>
                            }

                        </div>
                    }

                </div>
            </div>
        </div>

    );
}



const styles = StyleSheet.create({
    listItems: {
        flex: 0.5,
        //    alignSelf:'baseline',
        //s  alignItems: 'space-between',


    },
    listRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: 5,
        marginTop: 10,
        marginLeft: 5,
        marginBottom: 3,
        marginRight: 5,
        borderRadius: 10,
        borderWidth: 0,
        borderColor: '#D1D1D6',
        backgroundColor: 'white',

    },
    doctorTilteFont: {
        color: '#212121',
        fontSize: 17
    },
    patientFont: {
        marginTop: 3,
        fontSize: 16,
        color: '#455A64'
    },
    dateSectionMainStyle: {
        "opacity": 1,
        "position": "relative",
        "backgroundColor": "transparent",
        "width": 50,
        height: 75,
        marginTop: 10,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    dateSectionBackground: {
        opacity: 1,
        backgroundColor: "#1c4490",
        borderRadius: 5,
        width: 55,
        height: 65,

    },
    dateSectionTextPlacement: {
        "opacity": 1,
        "position": "absolute",
        height: '100%',
        width: '100%',
        borderRadius: 10,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',


    },
    appointmentTypeStyle: {
        marginTop: 5,
        marginBottom: 5,
        marginRight: 35,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',


    },

    appointmentTypeBox: {
        backgroundColor: '#C5C5C5',
        borderRadius: 5,
        marginBottom: 5,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    appointmentTypeStyleText: {
        textAlign: 'center',
        width: 80,
        marginBottom: 5,
        color: '#00A500',
        fontWeight: '400',
        fontSize: 16
    },
    toptextDate: {
        fontSize: 14,
        fontWeight: '400',
        color: '#fff'
    }


});

export default AppointmentHandler;
