import {
    GETTING_MEDS,
    GETTING_MEDS_SUCCESS,
    GETTING_MEDS_MORE_SUCCESS,
    GETTING_MEDS_FAILED,
} from "../constants/Constant";
import getAllMedications from "../services/MedicationService";
import getUserId from "../core/GetToken";



export const fetchAllMedsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getMed())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=10&PageNumber=' + pageNumber + '&TypeID=0'
            console.log(queryString);
            getAllMedications(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log(json[0].entry[0].resource.id);
                    json[0].hasOwnProperty('entry') ? dispatch(getMedSuccess(json[0].entry)) : dispatch(getMedFinished())
                })
                .catch(err => dispatch(getMedFailed(err)))
        })
    }
}


//start getting data
const getMed = () => {
    return {
        type: GETTING_MEDS
    }
}

//data is success
const getMedSuccess = (data) => {
    return {
        type: GETTING_MEDS_SUCCESS,
        data
    }
}
//data failed
const getMedFailed = () => {
    return {
        type: GETTING_MEDS_FAILED
    }
}

const getMedFinished = () => {
    return null;
}