import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ProfileItem = props => {

    return (
        <form className="forms-sample" style={{ marginTop: 20 }}>
            <div className="form-group row mb-0">

                {
                    props.title_B === "" ? (<div className="col-md-12">
                        <label htmlFor="validate-phone-field">{props.title_A}</label>
                        <div className="input-group">
                            <input type="text" id="validate-phone-field" className="form-control enable-mask phone-mask" value={props.value_A} readOnly />

                        </div>
                    </div>) : <div className="col-md-6">
                            <label htmlFor="validate-phone-field">{props.title_A}</label>
                            <div className="input-group">
                                <input type="text" id="validate-phone-field" className="form-control enable-mask phone-mask" value={props.value_A} readOnly />

                            </div>
                        </div>
                }
                {
                    props.title_B !== "" && <div className="col-md-6">
                        <label htmlFor="validate-internet-ip-field">{props.title_B}</label>
                        <div className="input-group">
                            <input type="text" id="validate-internet-ip-field" className="form-control enable-mask ip_address-mask" value={props.value_B} readOnly />

                        </div>
                    </div>
                }

            </div>
        </form>
    );
}

export default ProfileItem
