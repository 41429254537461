import React, { useEffect, useState } from 'react';
import getUserId from '../../shared/core/GetToken';
import { msgClenuplatData, sendNewMessagetoAPI, replyMessageToApi } from '../../shared/actions/NewMessageActions';
import { connect } from 'react-redux'
import { ActivityIndicator, TouchableOpacity, View, Text } from 'react-native-web';
import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'
// import { Modal, Button } from "react-bootstrap";

import { Modal, Button } from 'antd';
import { Alert } from 'antd';
import PDFViewer from 'pdf-viewer-reactjs'
import 'antd/dist/antd.css';
import Settings from '../../shared/config/Settings';






const MessageView = props => {
    const [show, setShow] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [myfile, setmyfile] = useState(false)
    const [fileType, setFileTpye] = useState('');
    const [fileConent, setFileContent] = useState('');




    //for drafts
    const { SendMessage, isSending, isSendMessageSuccess, error, isReplyMessageSuccess } = props.SendMessage
    //center information
    const [centerName, setCenterName] = useState('');
    const [centerRef, setCenterRef] = useState('');

    //recept information
    const [resourceType, setResourceType] = useState('');
    const [recpName, setRecpName] = useState('');

    //file information
    const [draftfile, setDraffile] = useState(false)
    const [dfileSize, setdFileSize] = useState('');
    const [dfileType, setdfileType] = useState('');
    const [dfileBase, setdFileBase] = useState('');



    const [object, setObject] = useState(false);


    function b() {
        if (!object) {
            console.log("called")
            setObject(true)
            setObject((state) => {
                return state;
            });
            return;
        }

    }

    const getMessageById = parms => {

        setIsLoading(true);
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };

            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&MessageID=' + parms;
            console.log(queryString)
            fetch(`${Settings.apiUrl}` + "Patient/MessageByID" + queryString, requestOptions)
                .then(response => response.text())
                .then(result => {
                    setIsLoading(false);
                    let mydata = JSON.parse(result);
                    console.log("DATA FOUND: " + mydata)


                    // document.getElementById('userImage').innerHTML = '<img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />'
                    document.getElementById('senderName').innerHTML = mydata[0].entry[0].resource?.recipient[0]?.display
                    document.getElementById('centerName').innerHTML = mydata[0].entry[0].resource.extension[7].valueReference.display
                    document.getElementById('messageDate').innerHTML = GetAppointmentDate(mydata[0].entry[0].resource.sent) + ' ' + GetMonthName(mydata[0].entry[0].resource.sent) + ' ' + GetYear(mydata[0].entry[0].resource.sent)
                    document.getElementById('messageDetails').innerHTML = mydata[0].entry[0].resource.extension[0].valueString


                    if (props.screen === 'Received') {
                        //only for reply purpose
                        document.getElementById('center_ref').value = mydata[0].entry[0].resource.extension[7].valueReference.reference
                        document.getElementById('provider_ref').value = mydata[0].entry[0].resource.recipient[0].reference
                        document.getElementById('center_name').value = mydata[0].entry[0].resource.extension[7].valueReference.display
                        document.getElementById('provider_name').value = mydata[0].entry[0].resource.extension[3].valueString
                        //  document.getElementById('message').value = mydata[0].entry[0].resource.extension[0].valueString
                        document.getElementById('subject').value = mydata[0].entry[0].resource.note[0].text
                        //only for reply end
                    }



                    if (mydata[0].entry[0].resource.hasOwnProperty('payload')) {
                        console.log("The file Type is" + mydata[0].entry[0].resource.payload[0].contentAttachment.contentType)


                        setFileTpye(mydata[0].entry[0].resource.payload[0].contentAttachment.contentType)
                        setFileTpye((state) => {
                            return state;
                        });

                        if (mydata[0].entry[0].resource.payload[0].contentAttachment.contentType === 'PDF') {
                            setmyfile(true);
                            setmyfile((state) => {
                                return state;
                            });

                            //document.getElementById("messagePDF").removeChild('object')
                            var obj = document.createElement('object');
                            obj.style.width = '100%';
                            obj.style.height = '550px';
                            obj.type = 'application/pdf';
                            obj.data = 'data:application/pdf;base64,' + mydata[0].entry[0].resource.payload[0].contentAttachment.data;

                            console.log(obj)
                            document.getElementById("messagePDF").appendChild(obj)
                            return

                        }
                        else {
                            console.log("Image")
                            setmyfile(true);
                            setmyfile((state) => {
                                return state;
                            });
                            setFileContent("data:image/" + mydata[0].entry[0].resource.payload[0].contentAttachment.contentType + ";base64, " + mydata[0].entry[0].resource.payload[0].contentAttachment.data);
                            setFileContent((state) => {
                                return state;
                            });
                        }




                    } else {
                        setmyfile(false);
                        setIsLoading(false);
                        setmyfile((state) => {
                            return state;
                        });
                        console.log("File Not Found")
                    }
                })
                .catch(err => {
                    console.log(err)
                    setIsLoading(false);
                    setmyfile(false);
                    setmyfile((state) => {
                        return state;
                    });
                })
        })
    }


    const diplsayFile = () => {

        if (fileType === 'PDF') {
            // console.log(fileConent)
            setShowPDF(true)
        } else {
            setShow(true)
        }
    }



    const getMessageByIdDraft = draftid => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&MessageID=' + draftid;
            fetch(`${Settings.apiUrl}` + "Patient/MessageByID" + queryString, requestOptions)
                .then(response => response.text())
                .then(result => {
                    setIsLoading(false);
                    let mydataDraft = JSON.parse(result);
                    // console.log(JSON.stringify(mydataDraft))

                    document.getElementById('center_ref').value = mydataDraft[0].entry[0].resource.extension[7].valueReference.reference
                    document.getElementById('provider_ref').value = mydataDraft[0].entry[0].resource.recipient[0].reference
                    document.getElementById('center_name').value = mydataDraft[0].entry[0].resource.extension[7].valueReference.display
                    document.getElementById('provider_name').value = mydataDraft[0].entry[0].resource.extension[3].valueString
                    document.getElementById('message').value = mydataDraft[0].entry[0].resource.extension[0].valueString
                    document.getElementById('subject').value = mydataDraft[0].entry[0].resource.note[0].text


                    if (mydataDraft[0].entry[0].resource.hasOwnProperty('payload')) {
                        setDraffile(true)
                        document.getElementById('fType').value = mydataDraft[0].entry[0].resource.payload[0].contentAttachment.contentType
                        document.getElementById('fBase').value = mydataDraft[0].entry[0].resource.payload[0].contentAttachment.data
                        document.getElementById('fSize').value = mydataDraft[0].entry[0].resource.payload[0].contentAttachment.size
                    } else {
                        setDraffile(false)
                        document.getElementById('fType').value = ''
                        document.getElementById('fBase').value = ''
                        document.getElementById('fSize').value = ''
                    }

                })
                .catch(err => {
                    setIsLoading(false);
                    console.log(err)
                })
        })
    }


    const [successMessage, setSuccessMessage] = useState('Your Message has been sent Successfully');
    const sendMyMessage = () => {
        let center_ref = document.getElementById('center_ref').value
        let provider_ref = document.getElementById('provider_ref').value
        let center_name = document.getElementById('center_name').value
        let provider_name = document.getElementById('provider_name').value
        let message = document.getElementById('message').value
        let subject = document.getElementById('subject').value

        let fType = document.getElementById('fType').value
        let fBase = document.getElementById('fBase').value
        let fSize = document.getElementById('fSize').value

        props.sendData(props.example, center_name, center_ref, subject, message, fType, fBase, fSize, provider_ref, provider_name, 'Send Mail')

    }


    const saveDraft = () => {
        setSuccessMessage('Draft has been updated');
        setSuccessMessage((state) => {
            return state;
        });

        let center_ref = document.getElementById('center_ref').value
        let provider_ref = document.getElementById('provider_ref').value
        let center_name = document.getElementById('center_name').value
        let provider_name = document.getElementById('provider_name').value
        let message = document.getElementById('message').value
        let subject = document.getElementById('subject').value

        let fType = document.getElementById('fType').value
        let fBase = document.getElementById('fBase').value
        let fSize = document.getElementById('fSize').value

        props.sendData(props.example, center_name, center_ref, subject, message, fType, fBase, fSize, provider_ref, provider_name, 'Drafts')

    }
    useEffect(() => {
        console.log(props.screen)
        if (props.example === 0) {
            console.log(props.screen)
            //return
        }
        else if (props.screen === "Drafts") {
            getMessageByIdDraft(props.example)
        } else {
            getMessageById(props.example)
        }

        getMessageById(props.example)

    }, [props.example])


    // const deleteItem = messageId => {
    //     alert(messageId)
    // }

    const deleteItem = messageid => {
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // alert(item.token + ' ' + messageId)
            fetch(`${Settings.apiUrl}` + "Patient/Message?Token=" + item.token + "&PatientID=" + item.userid + "&MessageID=" + messageid, requestOptions)
                .then(data => data.json())
                .then(json => {
                    window.location.reload()
                    //props.navigation.navigate('Messaging')
                })
                .catch(error => console.log('error', error))

        });
    }


    const replyMessage = () => {
        //alert('Click')
        let message = document.getElementById('replyMessage').value;

        if (message === '') {
            document.getElementById('replyerror').innerHTML = 'Your reply message cannot be empty'
            return
        }
        document.getElementById('replyerror').innerHTML = ''
        let center_ref = document.getElementById('center_ref').value
        let provider_ref = document.getElementById('provider_ref').value
        let center_name = document.getElementById('center_name').value
        let provider_name = document.getElementById('provider_name').value
        let subject = 'RE: ' + document.getElementById('subject').value
        console.log("provider_name",center_ref, provider_ref, center_name, provider_name, subject, message)


        props.replyData(props.example, center_name, center_ref, subject, message, '', '', '', provider_ref, provider_name, 'Send Mail')


    }

    return (


        <>
            {
                props.screen === "null" && <div className="email-content">
                    <p>
                        <b>Select Message to View</b>
                    </p>
                </div>

            }

            {
                isLoading && <View style={{ textAlign: 'center', marginTop: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                !isLoading && props.screen !== "Drafts" && <>
                    <div className="preview-header">
                        <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />
                        <p className="user_name" style={{ height: 25 }} id="senderName"></p>
                        <a className="user-email" href="" id="centerName"></a>
                        <p style={{ width: 100, paddingRight: 11 }} className="date" id="messageDate"></p>
                        <div className="btn-group email-actions mr-n0 mr-md-n4" data-toggle="buttons">
                            <button onClick={() => deleteItem(props.example)} className="btn btn-white btn-xs component-flat p-0">
                                <i className="mdi mdi-delete" />
                            </button>
                        </div>
                    </div>
                    <div className="email-container">
                        <div className="email-content">
                            <p id="messageDetails"></p>
                        </div>

                        {
                            myfile &&

                            <div className="email-attachments">
                                <p>Attachments</p>
                                <div className="attachment-wrapper">
                                    <TouchableOpacity onPress={() => diplsayFile()}>
                                        <div className="attachment attachment-danger">
                                            <p>Document.pdf</p>
                                            <span>Attachment 1</span>
                                            <div className="icon-wrapper">
                                                <i className="mdi mdi mdi-file-pdf" />
                                            </div>
                                        </div>
                                    </TouchableOpacity>
                                </div>
                            </div>

                        }


                        {
                            props.screen === 'Received' &&
                            <div className="email-reply">
                                <p>Reply</p>
                                <div className="textarea-wrapper">
                                    <input type="hidden" className="form-control" id="center_name" readOnly />
                                    <input type="hidden" className="form-control" id="center_ref" readOnly />
                                    <input type="hidden" className="form-control" id="provider_name" readOnly />
                                    <input type="hidden" className="form-control" id="provider_ref" readOnly />
                                    <input type="hidden" className="form-control" id="subject" />
                                    <textarea id="replyMessage" className="form-control" rows={5} defaultValue={""} placeholder="Type your reply here...." />

                                    <button onClick={() => replyMessage()} className="btn btn-primary" type="submit">
                                        <i className="mdi mdi-send" />
                                    </button>
                                </div>
                                <p id="replyerror" style={{ color: 'red' }}></p>
                            </div>
                        }


                        {
                            isSending && <ActivityIndicator size={"large"} />
                        }
                        {/* {
                            isReplyMessageSuccess && <Alert
                                message="Success"
                                description="Your reply has been sent successfully"
                                type="success"
                                showIcon
                            />

                        } */}
                        {
                            // isReplyMessageSuccess && window.location.reload()
                        }

                    </div>
                </>
            }

            {
                props.screen === "Drafts" && <div className="email-compose-body">


                    <div id="email-compose">

                        {
                       //     isSendMessageSuccess && window.location.reload()
                        }
                        <div className="form-group input-recipient">
                            <label className="label">Center</label>
                            <input type="text" className="form-control" id="center_name" readOnly />
                            <input type="hidden" className="form-control" id="center_ref" readOnly />
                        </div>


                        <div className="form-group input-recipient">
                            <label className="label">Provider</label>
                            <input type="text" className="form-control" id="provider_name" readOnly />
                            <input type="hidden" className="form-control" id="provider_ref" readOnly />
                        </div>

                        <div className="form-group">
                            <label className="label">Subject</label>
                            <input style={{ background: '#fff' }} type="text" className="form-control" id="subject" />

                            <input type="hidden" className="form-control" id="fType" readOnly />
                            <input type="hidden" className="form-control" id="fBase" readOnly />
                            <input type="hidden" className="form-control" id="fSize" readOnly />
                        </div>
                        <div className="form-group">
                            <label className="label">Message</label>
                            <textarea style={{ background: '#fff' }} className="form-control" rows={7} id="message" placeholder="Message" defaultValue={""} />
                        </div>
                        <div className="email-compose-toolbar">
                            {
                                draftfile && <div className="email-attachments">
                                    <p>Attachments</p>
                                    <div className="attachment-wrapper">
                                        <div className="attachment attachment-danger">
                                            <p>Attachment.pdf</p>
                                            <div className="icon-wrapper">
                                                <i className="mdi mdi mdi-file-pdf" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <button onClick={() => saveDraft()} className="btn btn-primary btn-sm component-flat send-button">Update Draft</button>
                            <button style={{ marginLeft: 10 }} onClick={() => sendMyMessage()} className="btn btn-success btn-sm component-flat send-button">Send</button>
                            {
                                isSending && <ActivityIndicator size={"large"} />
                            }
                            {
                                isSendMessageSuccess && <Alert
                                    message="Success"
                                    description={successMessage}
                                    type="success"
                                    showIcon
                                />

                            }
                        </div>
                    </div>
                </div>

            }
            {/* <Modal show={show} onHide={() => setShow(false)} backdrop="static"
                keyboard={false}>
                <Modal.Header className="modal-header bg-primary">
                    <Modal.Title className="modal-title text-white">File Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <img style={{ height: 450 }} class="img-responsive" src={fileConent} alt="Red dot" />


                    </div>
                    <button onClick={() => setShow(false)} className="btn btn-primary btn-block">Close</button>
                </Modal.Body>

            </Modal> */}

            <Modal
                title="File Content"
                centered
                visible={show}
                onOk={() => setShow(false)}
                onCancel={() => setShow(false)}
                width={1000}
            >
                <img style={{ height: '100%' , width: '100%' }} class="img-responsive" src={fileConent} alt="Red dot" />
            </Modal>


            <Modal
                title="PDF File"
                centered
                visible={showPDF}
                onOk={() => setShowPDF(false)}
                onCancel={() => setShowPDF(false)}
                afterClose={() => document.getElementById("messagePDF").innerHTML = ""}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
            >
                <div className="PDF" id="messagePDF">

                </div>
            </Modal>

        </>
    );
}




function mapStateToProps(state) {
    return {

        // SetMessageItem: state.SetMessageItem,
        SendMessage: state.SendMessage,
    }
}
// props.replyData(props.example, center_name, center_ref, subject, message, '', '', '', provider_ref, provider_name, 'Send Mail')
function mapDispatchToProps(dispatch) {
    return {
        sendData: (draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType) => dispatch(sendNewMessagetoAPI(draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType)),
        replyData: (draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType) => dispatch(replyMessageToApi(draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType)),

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageView)
// export default MessageView;
