import { GETTING_SENT_MESSAGE , GETTING_MESSAGE_SENT_SUCCESS , GETTING_MESSAGE_SENT_MORE_SUCCESS, GETTING_MESSAGE_SENT_FAIL  } from "../constants/Constant";
import getPaitentMsg from "../services/MessagingService";
import getUserId from "../core/GetToken";


export const fetchSentMessageFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getsSentMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber +'&Type=1&MessagingType=2'
            // console.log(queryString);
            getPaitentMsg(queryString)
                .then(data => data.json())
                .then(json => {
                    // console.log('Hello:',JSON.stringify(json))
                    json[0].hasOwnProperty('entry') ? dispatch(getsSentMessageSuccess(json[0].entry)) : dispatch(getsSentMessageFinsihed())
                })
                .catch(err => dispatch(getsSentMessageFail(err)))
        })
    }
}


export const fetchSentMoreMessageFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getsSentMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber +'&Type=1&MessagingType=2'
            getPaitentMsg(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getsSentMessageMoreSuccess(json[0].entry)) : dispatch(getsSentMessageFinsihed())
                })
                .catch(err => dispatch(getsSentMessageFail(err)))
        })
    }
}




const getsSentMessage = () =>{
    return{
        type: GETTING_SENT_MESSAGE
    }
}

//data success
const getsSentMessageSuccess = (data) => {
    return {
        type: GETTING_MESSAGE_SENT_SUCCESS,
        data,
    }
}

const getsSentMessageMoreSuccess = (data) => {
    return {
        type: GETTING_MESSAGE_SENT_MORE_SUCCESS,
        data,
    }
}

//data failed
const getsSentMessageFail = () => {
    return {
        type: GETTING_MESSAGE_SENT_FAIL
    }
}

const getsSentMessageFinsihed = () => {
    return null;
}


