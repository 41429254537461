import React, { useCallback, useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear, ShowDateImmune, GetHeadingDates } from '../../../shared/core/datehelper'
import { fetchImmunefromApi, fetchMoreImmunefromApi } from '../../../shared/actions/ImmuneActions';
const AllTabs = props => {

    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const { Immune, isGettingImmune } = props.Immune;


    useEffect(() => {
        //alert('Called Future')
        props.getAllImmune(pageSize, pageNumber);
    }, [])

    function GetTagColor(results) {
        // due immunization check.
        if (results.resource.extension[10].valueBoolean == true) {
            return 'card-header text-white bg-danger'
            // return { backgroundColor: Colors.highAllergy }
        }
        // due 
        else if (results.resource.extension[11].valueBoolean == false && results.resource.extension[10].valueBoolean == false && results.resource.status === undefined) {
            return 'card-header text-white bg-warning'
            //return { backgroundColor: Colors.midAllergy }
        }
        else if (results.resource.status !== undefined && results.resource.status == "completed") {

            return 'card-header text-white bg-success'
            //{ backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
        else if (results.resource.extension[11].valueBoolean == true) {
            return 'card-header text-white bg-warning'
        }
        else {
            // latter check
            return 'card-header text-white bg-success'
            // return { backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }

        }
    }



    function GetHeadingDateForAll(results) {

        if (results.resource.extension[10].valueBoolean == true) {
            //overdue
            return GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)

            // return 'card-header text-white bg-danger'
        }
        else if (results.resource.extension[11].valueBoolean == false && results.resource.extension[10].valueBoolean == false && results.resource.status === undefined) {
            //due
            return GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
            //return 'card-header text-white bg-warning'

        }
        else if (results.resource.status !== undefined && results.resource.status == "completed") {
            //admin

            return GetAppointmentDate(results.resource.date) + ' ' + GetMonthName(results.resource.date) + ' ' + GetYear(results.resource.date)


            //  return 'card-header text-white bg-success'

        } else {
            //null
            return GetAppointmentDate(results.resource.date) + ' ' + GetMonthName(results.resource.date) + ' ' + GetYear(results.resource.date)

            // return 'card-header text-white bg-success'


        }
    }
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div style={{ marginLeft: 10, alignItems: 'center' }}>
                        <span className="badge badge-success" style={{ marginLeft: 15, marginTop: 10 }}>Administered</span>
                        <span className="badge badge-danger" style={{ marginLeft: 15, marginTop: 10 }}>Overdue</span>
                        <span className="badge badge-warning" style={{ marginLeft: 15, marginTop: 10 }}>Due</span>
                    </div>
                </div>
            </div>
            {
                isGettingImmune && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (Immune) !== 'undefined' && Immune.length ? (
                    <FlatList
                        data={Immune}
                        renderItem={({ item }) => (
                            <ImmuneHandler
                                styles={GetTagColor(item)}
                                heading={item.resource.extension[1].valueString}//{GetHeading(item)}
                                date={
                                    GetAppointmentDate(item.resource.date) +
                                    " " +
                                    GetMonthName(item.resource.date) +
                                    " " +
                                    GetYear(item.resource.date)
                                  }
                                //   date={GetHeadingDateForAll(item)}//{GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}   //{ShowDateImmune(item)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                //  type_diagnoes={item.resource.extension[0].valueString}
                                indication={(item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : "N/A")}
                                vacc={(item.resource.extension[6].valueString != undefined ? item.resource.extension[6].valueString : "N/A")}
                                outcome={(item.resource.extension[14].valueString != undefined ? item.resource.extension[14].valueString : "N/A")}
                                datedue={GetHeadingDates(item)}
                                outcome={item.resource.extension[14].hasOwnProperty('valueString') ? item.resource.extension[14].valueString : 'N/A'}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                    />
                ) : !isGettingImmune && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>

            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        Immune: state.Immune
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllImmune: (pageSize, pageNumber) => dispatch(fetchImmunefromApi(pageSize, pageNumber)),
        //getAllImmuneMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreImmunefromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTabs)


// export default AllTabs
