export const emailValidator = email => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email cannot be empty';
    if (!re.test(email)) return 'Ooops! We need a valid email address';

    return '';
};

export const passwordValidator = password => {
    if (!password || password.length <= 0) return 'Password cannot be empty';

    return '';
};


export const nameValidator = name => {
    if (!name || name.length <= 0) return 'Name cannot be empty';

    return '';
};
export const getColorForInintials = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export const subjectValidetor = subject => {
    if (!subject || subject.length <= 0) return 'Subject is Required*';
    return '';
}

export const messageValidetor = usermsg => {
    if (!usermsg || usermsg.length <= 0) return 'Message is required*';
    return '';
}

export const recpValidator = recp => {
    if (!recp || recp.length <= 0) return 'Please select a Recepient';
    return '';
}

export const nhiValidator = (nhi, email) => {
    //if(!nhi || nhi.length <=0 || !email || email.length <=0) return 'NHI or Email cannot be empty';
    const re = /\S+@\S+\.\S+/;
    if (email.length > 0) {
        if (!re.test(email)) return 'Ooops! We need a valid email address';
        else return '';
    }

    if (nhi.length > email.length || email.length > nhi.length) return '';
    else return 'NHI or Email cannot be empty';
    return '';
};


export const getInitials = nameString => {
    return nameString.charAt(0).toUpperCase();
    // const regexChar = /\D\w+/
    // return nameString
    //     .trim() //remove trailing spaces
    //     .split(' ') // splits on spaces
    //     .filter(word => word.length > 0) // strip out double spaces
    //     .filter(word => regexChar.test(word)) // strip out special characters
    //     .map(word => word.substring(0, 1).toUpperCase()) // take first letter from each word and put into array
}

// return true if object is empty other wise return false
export const isEmpty = (object) => {
    return Object.keys(object).length === 0;
}

// retrun image/png, etc
export const detectMimeType = (b64) => {
    const body = { profilepic: b64 };
    let mimeType = body.profilepic.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return mimeType;
}

// return png, jpg, pdf etc
export const detectType = (b64) => {
    const body2 = { profilepic: b64 };
    let mimeType2 = body2.profilepic.match(/[^:/]\w+(?=;|,)/)[0];
    return mimeType2;
}


export const handlePrint = (htmlLetter,username) => {


    var bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec(htmlLetter)[1];
    console.log("hello test " + bodyHtml)
    //alert(bodyHtml);

    var a = window.open();
    a.document.write('<html>');

    a.document.write('<head>');
    a.document.write('<link href="https://devnhl.indici.ie/Content/style.css" rel="stylesheet"> <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet" type="text/css">  <link href="https://devnhl.indici.ie/Content/CustomCss/MailMerge/MailMergePrint.css" rel="stylesheet"> <script src="https://devnhl.indici.ie/Scripts/jquery-2.1.4.min.js"></script>');
    a.document.write('<style>.watermark{height:100%;display:block;position:relative}.watermark::after{content:"";background:url(https://banner2.cleanpng.com/20180512/oxe/kisspng-paper-rubber-stamp-stock-photography-royalty-free-5af72d03738f71.0212011215261483554733.jpg) no-repeat;opacity:0.2;top:0;left:0;bottom:0;right:0;position:absolute;z-index:-1} @media print{*{-webkit-print-color-adjust:exact}} *{-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}@media screen{div.divFooter{display: none;}}@media print{div.divFooter{position: fixed; bottom: 0;}}</style>')
    a.document.write('</head>');

    a.document.write('<body>');

    a.document.write('<div class="watermark"><div class="col-md-12">' + bodyHtml + '</div>')
    a.document.write('<div class="divFooter"><p id="hello"></p></div></div>');
    a.document.write('<div class="divFooter">' + username + '</div></div>');


    //a.document.write(htmlLetter);


    a.document.write('<script>var currentdate=new Date();var datetime="Printed: " + currentdate.getDate() + "/" + (currentdate.getMonth()+1) + "/" + currentdate.getFullYear() + " @ " + currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();document.getElementById("hello").innerHTML=datetime;</script>')
    a.document.write('</body></html>');




    a.document.close();


    setTimeout(function () { a.print(); }, 500);
    a.onfocus = function () { setTimeout(function () { a.close(); }, 500); }
}