import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import TreatmentHandler from "../../components/TreatmentHandler";
import { fetchTreactOfChoiceFromApi } from '../../../shared/actions/TreatmentOfChoneMedicationAction';
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
const TreatmentChoice = props => {

    const { TreatmentOfChoices, isGettingTreatmentOfChoices } = props.TreatmentOfChoices;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        //alert('Called Future')
        props.getTreatmentOfChoice(pageSize, pageNumber);
    }, [])
    return (
        <div>
            {
                isGettingTreatmentOfChoices && <View style={{ textAlign: 'center', justifyContent: 'center', marginBottom: 1, marginTop: 2 }}><ActivityIndicator size="large" color="#00A1DE" /></View>
            }
            {
                typeof (TreatmentOfChoices) !== 'undefined' && TreatmentOfChoices.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        data={TreatmentOfChoices}
                        renderItem={({ item }) => (
                            <TreatmentHandler
                                date={GetAppointmentDate(item.resource.created) + ' ' + GetMonthName(item.resource.created) + ' ' + GetYear(item.resource.created)}
                                type_diagnoes={item.resource.extension[0].valueString}
                                comments={item.resource.extension[1].valueString}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                    />
                ) : !isGettingTreatmentOfChoices && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>
            }
        </div>
    );
}



function mapStateToProps(state) {
    return {
        TreatmentOfChoices: state.TreatmentOfChoices
    }
}

function mapDispatchToProps(disptach) {
    return {
        getTreatmentOfChoice: (pageSize, pageNumber) => disptach(fetchTreactOfChoiceFromApi(pageSize, pageNumber)),
        // getTreatmentOfChoiceMore: (pageSize, pageNumber) => dispatch(fetchTreactOfChoiceMoreFromApi(pageSize, pageNumber, 'Not'))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TreatmentChoice);

// export default TreatmentChoice
