import { GETTING_COMPLETED_APPOINTMENT,GETTING_COMPLETED_APPOINTMENT_FINISHED, GETTING_COMPLETED_APPOINTMENT_SUCCESS, GETTING_COMPLETED_APPOINTMENTT_FAILURE, GETTING_COMPLETED_APPOINTMENT_MORE } from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";

//to get all diagnosis
export const fetchAppointmentsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getCompAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=3'
            getPaitentAppointments(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getCompAppointSucess(json[0].entry)) : dispatch(getCompAppointFinished())
                })
                .catch(err => dispatch(getCompAppointFail(err)))
        })
    }
}

//to get More diagnosis
export const fetchMoreAppointmentsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getCompAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=3'
            getPaitentAppointments(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getMoreCompAppointSucess(json[0].entry)) : dispatch(getCompAppointFinished())
                })
                .catch(err => dispatch(getCompAppointFail(err)))
        })
    }
}







const getCompAppoint = () => {
    return {
        type: GETTING_COMPLETED_APPOINTMENT
    }
}

//data success
const getCompAppointSucess = (data) => {
    return {
        type: GETTING_COMPLETED_APPOINTMENT_SUCCESS,
        data,
    }
}
//data success
const getMoreCompAppointSucess = (data) => {
    return {
        type: GETTING_COMPLETED_APPOINTMENT_MORE,
        data,
    }
}

//data failed
const getCompAppointFail = () => {
    return {
        type: GETTING_COMPLETED_APPOINTMENTT_FAILURE
    }
}


//data failed
const getCompAppointFinished = () => {
    return {
        type:GETTING_COMPLETED_APPOINTMENT_FINISHED
    }
}

