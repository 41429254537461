import React, { useState, useEffect } from 'react';

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator } from "react-native-web";

import { connect } from "react-redux";
import { fetchAllergyFromApi } from "../../shared/actions/AllergiesActions";
import { capitalize } from "../../shared/core/datehelper";
import AllergiesHandler from "../components/AllergiesHandler";


const AllergiesHome = props => {
    const { Profile, isFetching } = props.Profile;
    const [isloaded, setIsload] = React.useState(false);
    const { Allergies, isGetting } = props.Allergies;

    const [pageSize, setPageSize] = useState(1);           //state for the page number
    const [pageNumber, setPageNumber] = useState(30);
    const [error, setError] = useState('No Known Allergies')

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        //alert('Called Future')


        if (typeof (Profile) !== 'undefined' && Profile.length) {
            Profile[0].resource.extension.map((item, index) => {
                if (item.url == "NoKnownAllergies") {
                    if (!item.valueBoolean) {
                        setError('No Record Found')
                        props.getAlg(pageSize, pageNumber);

                    }
                }

            })
        }


        //   : null


    }, [])



    //find the tag color
    function GetTagColor(allergyLevel) {
        // if (allergyLevel === 'Severe') {
        //     return 'card-header text-white bg-danger';
        //     //return { backgroundColor: Colors.highAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        // }
        // if (allergyLevel === 'Moderate') {
        //     return 'card-header text-white bg-warning';
        //     // return { backgroundColor: Colors.midAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        // }
        // if (allergyLevel === 'Mild') {
        //     return 'card-header text-white bg-success';
        //     // return { backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        // }

        if (allergyLevel === "Mild") {
            return { backgroundColor: '#548E4D', borderRadius: 0 };
        }

        if (allergyLevel === "Low") {
            return { backgroundColor: '#18B241', borderRadius: 0 };
        }

        if (allergyLevel === "Moderate") {
            return { backgroundColor: '#F68647', borderRadius: 0 };
        }

        if (allergyLevel === "High") {
            return { backgroundColor: 'brown', borderRadius: 0 };
        }

        if (allergyLevel === "Severe") {
            return { backgroundColor: '#E2201C', borderRadius: 0 };
        }


        return { backgroundColor: '#11578F', borderRadius: 0 };
        // }
    }


    //find the tag name
    function GetTagName(allergyLevel) {
        if (allergyLevel === 'Severe') {
            return 'High'
        }
        if (allergyLevel === 'Moderate') {
            return 'Moderate'
        }
        if (allergyLevel === 'mild' || allergyLevel === 'Mild') {
            return 'Low'
        }
    }
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Allergies</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Allergies</h2>
                                            <div className="item-wrapper">

                                            {/* <div className="item-wrapper"> */}
                                                    {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                    {
                                                        typeof Profile !== "undefined" && Profile.length ? (
                                                            // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                                            Profile[0].resource.extension.map((item, index) => {

                                                                return (
                                                                    item.url == "AllergyDisclaimer" &&
                                                                    <div class="row mb-2">
                                                                        {isFetching && <ActivityIndicator color='blue' size='small' />}
                                                                        <div class="col-md-8 mx-auto">
                                                                            <div class="alert alert-warning" role="alert">
                                                                                <h6 class="alert-heading">Please Note:</h6>

                                                                                <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                )
                                                            })
                                                        ) : null

                                                    }




                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={{ marginLeft: 10, alignItems: 'center' }}>
                                                            <span className="badge" style={{ marginLeft: 15, marginTop: 10, backgroundColor: '#18B241', color: '#fff' }}>Low</span>
                                                            <span className="badge" style={{ marginLeft: 15, marginTop: 10, backgroundColor: '#548E4D', color: '#fff' }}>Mild</span>
                                                            <span className="badge" style={{ marginLeft: 15, marginTop: 10, backgroundColor: '#F68647', color: '#fff' }}>Moderate</span>
                                                            <span className="badge" style={{ marginLeft: 15, marginTop: 10, backgroundColor: 'brown', color: '#fff' }}>High</span>
                                                            <span className="badge" style={{ marginLeft: 15, marginTop: 10, backgroundColor: '#E2201C', color: '#fff' }}>Severe</span>
                                                            <span className="badge" style={{ marginLeft: 15, marginTop: 10, backgroundColor: '#11578F', color: '#fff' }}>Not Specified</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    isGetting && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                }
                                                {
                                                    typeof (Allergies) !== 'undefined' && Allergies.length ? (
                                                        <FlatList
                                                            style={{ marginTop: 15 }}
                                                            data={Allergies}
                                                            renderItem={({ item }) => (


                                                                <AllergiesHandler
                                                                    // styles={GetTagColor(item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].severity : 'low')}
                                                                    styles={GetTagColor(item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString : 'low')}
                                                                    // algLevel={GetTagName(item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].severity : 'low')}
                                                                    algLevel={GetTagName(item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString : 'low')}
                                                                    // allergyLevel={item.resource.hasOwnProperty('reaction') ? capitalize(item.resource.reaction[0].severity) : 'Low'}
                                                                    allergyLevel={item.resource.hasOwnProperty('extension') ? capitalize(item.resource.extension[1].valueString) : 'Low'}
                                                                    algTitle={capitalize(item.resource.code.text)}
                                                                    algType={item.resource.extension[0].valueString}
                                                                    symptoms={item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].manifestation[0].text : '(Not Available)'}
                                                                    comment={item.resource.hasOwnProperty('note') ? item.resource.note[0].text : '(Not Available)'}
                                                                />
                                                            )}
                                                            keyExtractor={item => item.resource.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            windowSize={7}

                                                        />
                                                    ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <Text style={{ marginTop: 10, }}>{error}</Text>
                                                    </View>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}




function mapStateToProps(state) {
    return {
        Allergies: state.Allergies,
        Profile: state.Profile,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getAlg: (pageNumber, pageSize) => disptach(fetchAllergyFromApi(pageNumber, pageSize)),
        //getMoreAlg: (token, pageNumber, pageSize) => disptach(fetchAllergyFromApi(token, pageNumber, pageSize)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllergiesHome)
//export default AllergiesHome
