import React, { useCallback, useState, useEffect } from 'react';
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';

import { connect } from "react-redux";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { fetchResourcesFromApi, fetchMoreResourcesFromApi } from "../../shared/actions/ResourcesActions";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'

import { Card, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import ResourcesHandler from "../components/ResourcesHandler";
import "../components/css/drive.css";
import { fetchProfileFromApi } from '../../shared/actions/ProfileActions';
const gridStyle = {
    width: '50%',
    textAlign: 'center',
};
const ResourcesHome = props => {
    const { Resources, isGetting } = props.Resources;
    const { Profile, isFetching } = props.Profile;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [isloaded, setIsload] = React.useState(false);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])


    useEffect(() => {
        props.getResources(pageSize, pageNumber);
    }, [])


    const checkItems = resourceInfo => {
        alert('yooyoyoyoy')
    }
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Resources</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Resources</h2>
                                            <div className="item-wrapper">
                                                {/* <div class="row mb-2">
                                                    <div class="col-md-8 mx-auto">
                                                        <div class="alert alert-warning" role="alert">
                                                            <h6 class="alert-heading">Please Note:</h6> */}
                                                            {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                            {
                                                                typeof Profile !== "undefined" && Profile.length ? (
                                                                    // <p>{Profile[0].resource.extension[9].hasOwnProperty('valueString') ? Profile[0].resource.extension[9].valueString :"N/A" }</p>
                                                                    Profile[0].resource.extension.map((item, index) => {

                                                                        return (
                                                                            item.url == "ResourceDisclaimer" &&
                                                                            <div class="row mb-2">
                                                                            {isFetching && <ActivityIndicator color='blue' size='small' />}
                                                                      <div class="col-md-8 mx-auto">
                                                                          <div class="alert alert-warning" role="alert">
                                                                              <h6 class="alert-heading">Please Note:</h6>

                                                                              <p>{item.valueString ? item.valueString : "N/A"}</p>
                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                        )
                                                                    })
                                                                ) : null

                                                            }
                                                            {/* {Profile.map((person, i) => {
                                                                    <p>{person.resource.extension[8] !== undefined
                                                                        ? person.resource.extension[8].valueString
                                                                        : 'NO Data Found'}</p>
                                                                })} */}
                                                            {/* <p>   {!isFetching && typeof (Profile[0].resource.extension[8].valueString) !== 'undefined' ? Profile[0].resource.extension[8].valueString : null}</p> */}
                                                            {/* <p>{state.immuneMg}</p> */}
                                                            {/* <p>Please be aware that the vaccination information displayed on this screen relates only to vaccines that were scheduled and/or administered by your treatment centre. Any vaccines administered outside of your treatment centre will not be recorded here</p> */}
                                                        {/* </div>
                                                    </div>
                                                </div> */}
                                                <div className="drive-wrapper drive-grid-view">
                                                    <div className="grid-items-wrapper">
                                                        {
                                                            isGetting && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                        }
                                                        {
                                                            // Resources != null && Resources != undefined && Resources.map((item) => {
                                                            //     return (
                                                            //         <ResourcesHandler />
                                                            //     )
                                                            // })

                                                            typeof (Resources) !== 'undefined' && Resources.length ? (
                                                                Resources.map((item) => {
                                                                    return (

                                                                        <ResourcesHandler
                                                                            name={item.resource.content.title}
                                                                            responseType={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? 'fa fa-file-pdf-o text-danger' : 'fa fa-link text-warning' : null}
                                                                            contentType={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? 'PDF' : 'URL' : null}
                                                                            contentFlow={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? item.resource.id : item.resource.content.url : null}
                                                                        />

                                                                    )
                                                                })
                                                            ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                                <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                                            </View>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}


function mapStateToProps(state) {
    return {
        Resources: state.Resources,

        Profile: state.Profile,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getProfile: () => disptach(fetchProfileFromApi()),
        getResources: (pageSize, pageNumber) => disptach(fetchResourcesFromApi(pageSize, pageNumber)),
        //getMoreResources: (token, pageSize, pageNumber) => disptach(fetchMoreResourcesFromApi(token, pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourcesHome)
//export default ResourcesHome
