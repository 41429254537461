import { GETTING_TIMELINE , GETTING_TIMELINE_SUCCESS,GETTING_TIMELINE_SEARCH_SUCCESS , GETTING_TIMELINE_FAILED, GETTING_TIMELINE_MORE_SUCCESS, GETTING_TIMELINE_FINISHED } from "../constants/Constant";
import getPaitentTimeline from "../services/TimelineService";
import getUserId from "../core/GetToken";


export const fetchTimelineFromApi = (pageSize, pageNumber,fromDate,toDate) => {
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber +"&FromDate="+fromDate+"&toDate="+toDate
            getPaitentTimeline(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log('Hello:', json)
                    dispatch(getTimelineSuccess(json[0].entry))
                })
                .catch(err => dispatch(getTimelineFailed(err)))
        })


    }
}
export const fetchMoreTimelineFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            getPaitentTimeline(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log('Hello:', json)
                    // dispatch(getTimelineSuccess(json[0].entry))
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getMoreTimelineSuccess(json[0].entry)) : dispatch(getTimelineFinished())
                    } else {
                        dispatch(getTimelineFinished())
                    }
                })
                .catch(err => dispatch(getTimelineFailed(err)))
        })


    }
}

export const fetchTimelineSearchFromApi = (fromdate,todate) => {
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)
            let queryString='';
            if(fromdate!= '' && todate !='')
         {
            queryString = '?Token=' + item.token + '&PatientID='+item.userid+ '&FromDate='+fromdate+'&ToDate='+todate
         }
          console.log('string::',queryString)
           getPaitentTimeline(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log('Hello:', json)
                    dispatch(getTimelineSearchSuccess(json[0].entry))
                })
                .catch(err => dispatch(getTimelineFailed(err)))
        })
    }
}

const getTimeline = () =>{
    return{
        type: GETTING_TIMELINE
    }
}

const getTimelineSuccess = (data) => {
    return{
        type: GETTING_TIMELINE_SUCCESS,
        data
    }
}
const getTimelineSearchSuccess = (data) => {
    return{
        type: GETTING_TIMELINE_SEARCH_SUCCESS,
        data
    }
}
const getMoreTimelineSuccess = (data) => {
    return{
        type: GETTING_TIMELINE_MORE_SUCCESS,
        data
    }
}

const getTimelineFailed = () => {
    return{
        type: GETTING_TIMELINE_FAILED
    }
}

const getTimelineFinished = () => {
    return{
        type: GETTING_TIMELINE_FINISHED
    }
}