


export const GetDayName = date => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
};

// function getDayName(date) {
//     var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//     var d = new Date(date);
//     var dayName = days[d.getDay()];
//     return dayName;
// }


export const GetAppointmentDate = date => {
    var d = new Date(date);
    var n = d.getDate();
    return n;
}

// function getAppointmentDate(date) {
//     var d = new Date(date);
//     var n = d.getDate();
//     return n;
// }


export const GetMonthName = date => {
    var days = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = new Date(date);
    var monthName = days[d.getMonth()];
    return monthName;
}


export const GetMonthNumber = date => {
    var days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    var d = new Date(date);
    var monthName = days[d.getMonth()];
    return monthName;
}


export const GetYear = date => {
    var d = new Date(date);
    var n = d.getFullYear();
    return n;
}

// function getMonthName(date) {
//     var days = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oc', 'Nov', 'Dec'];
//     var d = new Date(date);
//     var monthName = days[d.getMonth()];
//     return monthName;
// }


export const ReturnTypeIcon = type => {
    // if (type === 'Telephonic') {
    //     return require('../../assets/telephone.png');
    // }
    // if (type === 'Video') {
    //     return require('../../assets/video.png');
    // }
    // if (type === 'F2F') {
    //     return require('../../assets/face.png');
    // }
    return 'a';
}


export const FomraatedDate = currentDate => {
    let date = new Date(currentDate);
    let weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dateString = weekdayNames[date.getDay()] + " " + date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear()

    return dateString;
}
export const getformatedDate = currentDate => {
    var timedate = new Date(currentDate);
    var year = timedate.getFullYear();
    var month = timedate.getMonth() + 1 //getMonth is zero based;
    var day = timedate.getDate();
    if (month.toString().length < 2) month = '0' + month;
    if (day.toString().length < 2) day = '0' + day;

    var formatted = year + "-" + day + "-" + month;
    return formatted;
}
export const FormatAMPM = currentDate => {
    let date = new Date(currentDate);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const FormateUTCTime = currentDateTIme => {
    let date = new Date(currentDateTIme);
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// function returnTypeIcon(type) {
//     if (type === 'Telephonic') {
//         return require('../assets/telephone.png');
//     }
//     if (type === 'Video') {
//         return require('../assets/video.png');
//     }
//     if (type === 'F2F') {
//         return require('../assets/face.png');
//     }
// }




export const capitalize = word => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const GetHeading = results => {
    if (results.resource.extension[10].valueBoolean == true) {
        return 'Was due on'
    }
    else if (results.resource.extension[11].valueBoolean == true) {
        return 'Due on'
    }
    else {
        return 'Adminstered on:'
    }
}


export const ShowDateImmune = results => {
    if (results.resource.extension[10].valueBoolean == true) {
        return ''
    }
    else if (results.resource.extension[11].valueBoolean == true) {
        return ''
    }
    else {
        return GetAppointmentDate(results.resource.extension[12].valueDateTime) + ' ' + GetMonthName(results.resource.extension[12].valueDateTime) + ' ' + GetYear(results.resource.extension[12].valueDateTime)
    }
}

export const GetHeadingDates = results => {
    if (results.resource.extension[10].valueBoolean == true) {
        return 'Was Due: ' + GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
    }
    else if (results.resource.extension[11].valueBoolean == true) {
        return 'Due Date: ' + GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
    }
    else {
        return 'Due Date: ' + GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
    }
}
