import React, { useRef, useState } from 'react';
import { StyleSheet, Text, View, Image, Dimensions, ActivityIndicator } from 'react-native';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';

import 'antd/dist/antd.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import './css/watermark.css'

import getUserId from "../../shared/core/GetToken"
import getRandomColor from '../../shared/core/generateRandomColor';
import Settings from '../../shared/config/Settings';
import Iframe from './Iframe';

const renderToolbar = (Toolbar) => (
    <>
        <Toolbar />
        <div
            style={{
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                marginTop: '4px',
                padding: '4px',
            }}
        >
            Custom element
        </div>
    </>
);
const ResourcesHandler = props => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin(
        renderToolbar
    );
    const [visible, setVisible] = useState(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [show, setShow] = React.useState(false)
    const [checkFile, setCheckFile] = useState(false);
    const [pdfVisible, setPdfVisible] = useState(false);

    const componentRef = useRef();

    const [file, setFile] = React.useState()

    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };
    const checkItems = resourceInfo => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        // setVisible(true);
        getUserId().then((userInfo) => {
            setIsLoadingPdf(true)

            //document.getElementById("showPDF").innerHTML = '<p>kjhdakjadshkajsdhdsakhsdakjh</p>'
            const item = JSON.parse(userInfo);
            fetch(Settings.apiUrl + "Patient/MediaByID?Token=" + item.token + "&PatientID=" + item.userid + "&ResourceID=" + resourceInfo, requestOptions)
                .then(data => data.json())
                .then(json => {
                    console.log(json[0].entry[0].resource.content);
                    if (json[0].entry[0].resource.content.contentType == "PDF") {
                        setPdfVisible(true)


                        // console.log('Hello:', json[0].entry[0].resource.resourceType)
                        //alert(json[0].entry[0].resource.resourceType);
                        var b64 = json[0].entry[0].resource.content.data;
                        // var bin = atob(b64);

                        // // Embed the PDF into the HTML page and show it to the user
                        var obj = document.createElement('object');
                        obj.style.width = '100%';
                        obj.style.height = '550px';
                        obj.type = 'application/pdf';
                        obj.data = 'data:application/pdf;base64,' + b64;
                        //document.body.appendChild(obj);

                        // `base64String` is the given base 64 data
                        const blob = base64toBlob("data:application/pdf;base64," + json[0].entry[0].resource.content.data);
                        const url = URL.createObjectURL(blob);
                        setFile(url)
                        setFile((state) => {
                            return state;
                        });
                        // document.getElementById("showPDF").appendChild(obj)
                        setIsLoadingPdf(false);

                        return
                    } else {
                      
                    }

                   

                    // document.getElementById("showPDF").appendChild(obj)
                    // setIsLoadingPdf(false);

                    // setShow(visible => !visible);
                    // setShow((state) => {
                    //     return state;
                    // });

                    // setFile('data:application/pdf;base64,' + json[0].entry[0].resource.content.data)
                    // setFile((state) => {
                    //     return state;
                    // });
                    // // console.log('Hello:', json[0].entry[0].resource.resourceType)
                    // //alert(json[0].entry[0].resource.resourceType);
                    // var b64 = json[0].entry[0].resource.content.data;
                    // // var bin = atob(b64);


                    // // // Embed the PDF into the HTML page and show it to the user
                    // var obj = document.createElement('object');
                    // obj.style.width = '100%';
                    // obj.style.height = '550px';
                    // obj.type = 'application/pdf';
                    // obj.data = 'data:application/pdf;base64,' + b64;
                    // //document.body.appendChild(obj);
                    // document.getElementById("showPDF").appendChild(obj)

                })
                .catch(error => setIsLoadingPdf(false))
                .finally(() => {
                    // setCheckFile(false)
                    setIsLoadingPdf(false)

                });
        });

    }
    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    //responseType  <a href="#"><i className="fa fa-file-pdf-o text-danger" /></a>
    return (
        <div>
            <div className="drive-item module text-center">
                <div className="drive-item-inner module-inner">
                    <div className="drive-item-title" ><a href="#" style={{ color: '#000' }}>{props.name}</a></div>

                    <div className="drive-item-thumb">
                        <a href="#"><i className={props.responseType} /></a>
                    </div>
                </div>
                <div className="drive-item-footer module-footer">
                    <ul className="utilities list-inline">
                        <li><a href="#" onClick={() => props.contentType === 'URL' ? window.open(props.contentFlow, '_blank') : checkItems(props.contentFlow)} data-toggle="tooltip" data-placement="top" title data-original-title="Download">View </a>{isLoadingPdf && <ActivityIndicator size="small" color="#00A1DE" />}</li>

                    </ul>
                </div>



                {/* <Modal
                    title="Letter Details"
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    // afterClose={() => document.getElementById("showPDFLetters").innerHTML = ""}
                    //style={{ top: 10, bottom: 90, paddingBottom: 30 }}
                    width={1000}
                    footer={[
                        <Button key="back" onClick={() => setVisible(false)}>
                            Close
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => handlePrint()}>
                            Print
                        </Button>
                    ]}
                >
                    <div style={{ height: '100%', width: '100%' }}>
                        <div ref={componentRef} style={{ padding: 30, marginRight: 25 }} className="PDF" id="showPDFLetters">

                            <div className="watermark">

                                <Iframe printRef={componentRef} content={htmlLetter} />
                            </div>

                        </div>
                    </div >

                </Modal > */}



                {/* <Modal
                    title="PDF File"
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '750px',
                        }}
                    >
                        <Viewer fileUrl={file} plugins={[
                            defaultLayoutPluginInstance,
                        ]} />
                    </div>
                    <div className="PDF" id="showPDF">

                    </div>
                    <p id="hee">
                        {isLoadingPdf && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                    </p>
                </Modal> */}

              
                <Modal
                    title="PDF File"
                    centered
                    visible={pdfVisible}
                    onOk={() => setPdfVisible(false)}
                    onCancel={() => setPdfVisible(false)}
                    afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '750px',
                        }}
                    >
                        <Viewer fileUrl={file} plugins={[
                            defaultLayoutPluginInstance,
                        ]} />
                    </div>
                    <div className="PDF" id="showPDF">
                    </div>
                    <p id="hee">
                        {isLoadingPdf && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                    </p>
                </Modal>


            </div >


        </div>


    );
}

export default ResourcesHandler
