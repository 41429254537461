import RoseAlert from "../../app/components/RoseAlert";
import Settings from "../config/Settings";
import {
    START_SEND_SERVICE,
    START_SEND_SERVICE_SUCCESS,
    START_SEND_SERVICE_REPLY_SUCCESS,
    START_SEND_SERVICE_CLEANUP,
    START_SEND_SERVICE_FAILED
} from "../constants/Constant";
import getUserId from "../core/GetToken";


{/**
*******************************************************************************************************************
********************************************* Reducer name is SendMessage.js *******************************************
*******************************************************************************************************************
*/}


// draftMessageId is used for to remove the draft message from the api and put it into the send message 

export const sendNewMessagetoAPI = (draftMessageId, centerName, centerRefrence, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType,sendMessageSuccesfully) => {
    return (disptach) => {
        disptach(sendMessage())

        // alert(msgType)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // const queryString = '?Token='+userToken;
            console.log("ie==>" + JSON.stringify(item))
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            // var raw = JSON.stringify({ "resourceType": "Communication", "extension": [{ "url": "MessageBody", "valueString": "'" + emailText + "'" }, { "url": "MessageType", "valueString": "Send Mail" }], "status": "completed", "recipient": [{ "reference": "'" + resourceType + "'", "display": "'" + msgRecp + "'" }], "sender": { "reference": "Patient/17308", "display": "Miciki CC Mouse" }, "payload": [{ "contentAttachment": { "contentType": "'" + fileType + "'", "data": "'" + fileBase + "'", "size": fileSize } }], "note": [{ "text": "'" + subject + "'" }] });

            var raw = null;
            {
                // fileBase is the image in base 64 if user is not select the image then call the api with out the image.
                fileSize ?
                    raw = {
                        "resourceType": "Communication",
                        "extension": [
                            {
                                "url": "MessageBody",
                                "valueString": "" + emailText + ""
                            },
                            {
                                "url": "MessageType",
                                "valueString": "" + msgType + ""
                            },
                            {
                                "url": "Centre",
                                "valueReference": {
                                    "reference": "Location/" + centerRefrence + "",
                                    "display": "" + centerName + ""
                                }
                            }
                        ],
                        "status": "completed",
                        "recipient": [
                            {
                                "reference": "" + resourceType + "",
                                "display": "" + msgRecp + ""
                            }
                        ],
                        "sender": {
                            "reference": "Patient/" + item.userid,
                            "display": "" + item.username + ""
                        },
                        "payload": fileSize?.map((item, index) => (
                            {
                                "contentAttachment": {
                                    "contentType": "" + item?.type + "",
                                    "data": "" + item?.base64 + "",
                                    "size": item?.size
                                }
                            }
                        )),
                        "note": [
                            {
                                "text": "" + subject + ""
                            }
                        ]
                    }
                    :
                    raw = {
                        "resourceType": "Communication",
                        "extension": [
                            {
                                "url": "MessageBody",
                                "valueString": "" + emailText + ""
                            },
                            {
                                "url": "MessageType",
                                "valueString": "" + msgType + ""
                            },
                            {
                                "url": "Centre",
                                "valueReference": {
                                    "reference": "Location/" + centerRefrence + "",
                                    "display": "" + centerName + ""
                                }
                            }
                        ],
                        "status": "completed",
                        "recipient": [
                            {
                                "reference": "" + resourceType + "",
                                "display": "" + msgRecp + ""
                            }
                        ],
                        "sender": {
                            "reference": "Patient/" + item.userid,
                            "display": "" + item.username + ""
                        },
                        "note": [
                            {
                                "text": "" + subject + ""
                            }
                        ]
                    }

            }


            if (draftMessageId != null) {
                raw["id"] = draftMessageId
            } else {
                // do something
            }


            console.log("Rawconsole===>" + JSON.stringify(raw))

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(raw),

            };

            console.log("new Message", item.token);
            fetch(Settings.apiUrl + "Patient/Message?Token=" + item.token, requestOptions)
                .then(response => response.text())
                .then(result => {
                    
                    RoseAlert("success", result.replace(/['"]+/g, ''))
                    disptach(sendMessageSuccess(result))
                    sendMessageSuccesfully()

                })
                .catch(err => {
                    RoseAlert("warning", "Try again..."+err)
                    disptach(sendMessageFailed("catch exectuted"))
                    console.log("err" + JSON.stringify(err))
                })
        })
    }
}






export const replyMessageToApi = (draftMessageId, centerName, centerRefrence, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType,sendMessageSuccesfully,parentid) => {
    return (disptach) => {
        disptach(sendMessage())

        // alert(msgType)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // const queryString = '?Token='+userToken;
            console.log("ie==>" + JSON.stringify(item))
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            // var raw = JSON.stringify({ "resourceType": "Communication", "extension": [{ "url": "MessageBody", "valueString": "'" + emailText + "'" }, { "url": "MessageType", "valueString": "Send Mail" }], "status": "completed", "recipient": [{ "reference": "'" + resourceType + "'", "display": "'" + msgRecp + "'" }], "sender": { "reference": "Patient/17308", "display": "Miciki CC Mouse" }, "payload": [{ "contentAttachment": { "contentType": "'" + fileType + "'", "data": "'" + fileBase + "'", "size": fileSize } }], "note": [{ "text": "'" + subject + "'" }] });

            var raw = null;
            {
                // fileBase is the image in base 64 if user is not select the image then call the api with out the image.
                fileSize ?
                    raw = {
                        "resourceType": "Communication",
                        "extension": [
                            {
                                "url": "MessageBody",
                                "valueString": "" + emailText + ""
                            },
                            {
                                "url": "MessageType",
                                "valueString": "" + msgType + ""
                            },
                            {
                                "url": "Centre",
                                "valueReference": {
                                    "reference": "Location/" + centerRefrence + "",
                                    "display": "" + centerName + ""
                                }
                            },
                            {
                                "url": "ParentMessageID",
                                "valueString": parentid
                            }
                        ],
                        "status": "completed",
                        "recipient": [
                            {
                                "reference": "" + resourceType + "",
                                "display": "" + msgRecp + ""
                            }
                        ],
                        "sender": {

                            "reference": "Patient/" + item.userid,
                            "display": "" + item.username + ""
                        },
                        "payload": fileSize.map((item, index) => (
                            {
                                "contentAttachment": {
                                    "contentType": "" + item?.type + "",
                                    "data": "" + item?.base64 + "",
                                    "size": item?.size
                                }
                            }
                        )),
                        "note": [
                            {
                                "text": "" + subject + ""
                            }
                        ]
                    }
                    :
                    raw = {
                        "resourceType": "Communication",
                        "extension": [
                            {
                                "url": "MessageBody",
                                "valueString": "" + emailText + ""
                            },
                            {
                                "url": "MessageType",
                                "valueString": "" + msgType + ""
                            },
                            {
                                "url": "Centre",
                                "valueReference": {
                                    "reference": "Location/" + centerRefrence + "",
                                    "display": "" + centerName + ""
                                }
                            }
                        ],
                        "status": "completed",
                        "recipient": [
                            {
                                "reference": "" + resourceType + "",
                                "display": "" + msgRecp + ""
                            }
                        ],
                        "sender": {
                            "reference": "Patient/" + item.userid,
                            "display": "Agnes OBEL"
                        },
                        "note": [
                            {
                                "text": "" + subject + ""
                            }
                        ]
                    }

            }


            if (draftMessageId != null) {
                raw["id"] = draftMessageId
            } else {
                // do something
            }


            console.log("Rawconsole===>" + JSON.stringify(raw))

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(raw),

            };

            console.log("new Message", item.token);
            fetch(Settings.apiUrl + "Patient/Message?Token=" + item.token, requestOptions)
                .then(response => response.text())
                .then(result => {

                    RoseAlert("success", result.replace(/['"]+/g, ''))
                    disptach(sendMessageReplySuccess(result))
                    sendMessageSuccesfully()
                })
                .catch(err => {
                    disptach(sendMessageFailed("catch exectuted"))
                    console.log("err" + JSON.stringify(err))
                })
        })
    }
}







//for state reset
export const msgClenuplatData = () => {
    return (disptach) => {
        disptach(sendMessageCleanUp())
    }
}


const sendMessage = () => {
    return {
        type: START_SEND_SERVICE
    }
}

//data success
const sendMessageSuccess = (data) => {
    return {
        type: START_SEND_SERVICE_SUCCESS,
        data,
    }
}


//data success
const sendMessageReplySuccess = (data) => {
    return {
        type: START_SEND_SERVICE_REPLY_SUCCESS,
        data,
    }
}

//data failed
const sendMessageFailed = (data) => {
    return {
        type: START_SEND_SERVICE_FAILED,
        data
    }
}

const sendMessageCleanUp = () => {
    return {
        type: START_SEND_SERVICE_CLEANUP
    }
}

