import React, { useCallback, useState, useEffect } from 'react';
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';

import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import { Timeline } from 'antd';
import 'antd/dist/antd.css';

import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'
import { fetchTimelineFromApi, fetchMoreTimelineFromApi } from "../../shared/actions/TimelineActions";

const TimelineHome = props => {

    const [isloaded, setIsload] = React.useState(false);
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const { PatientTimeline, isGetting } = props.PatientTimeline;
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])



    useEffect(() => {
        //alert('Called Future')
        props.getTimeline(pageSize, pageNumber);
    }, [])

    function timeDifference(current, previous) {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + ' seconds ago';
        }

        else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' minutes ago';
        }

        else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + ' hours ago';
        }

        else if (elapsed < msPerMonth) {
            return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
        }

        else if (elapsed < msPerYear) {
            return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
        }

        else {
            return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
        }
    }
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Timeline</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Timeline</h2>
                                            <div className="item-wrapper">

                                                {
                                                    isGetting && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                }
                                                {
                                                    typeof (PatientTimeline) !== 'undefined' && PatientTimeline.length ? (
                                                        <Timeline>
                                                            <FlatList
                                                                data={PatientTimeline}
                                                                style={{
                                                                    flexDirection: 'column', paddingLeft: 10,
                                                                    paddingRight: 10,
                                                                    paddingBottom: 10
                                                                }}
                                                                renderItem={({ item }) => (

                                                                    <Timeline.Item color="red">
                                                                        <div className="card gedf-card">
                                                                            <div className="card-header">
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <div className="mr-2">
                                                                                            <img className="rounded-circle" width={45} src="https://picsum.photos/50/50" alt />
                                                                                        </div>
                                                                                        <div className="ml-2">
                                                                                            <div className="h5 m-0">{item.resource.author.display}</div>
                                                                                            <div className="h7 text-muted">{GetAppointmentDate(item.resource.created) + ' ' + GetMonthName(item.resource.created) + ' ' + GetYear(item.resource.created)}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="text-muted h7 mb-2"> <i className="fa fa-clock-o" />10 min ago</div>
                                                                                <a className="card-link" href="#">
                                                                                    <h5 className="card-title">{item.resource.code.coding[0].display}</h5>
                                                                                </a>
                                                                                <p className="card-text">{item.resource.code.text}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Timeline.Item>

                                                                )}
                                                                keyExtractor={item => item.resource.id}
                                                                initialNumToRender={10}
                                                                maxToRenderPerBatch={10}
                                                                windowSize={7}

                                                            />
                                                        </Timeline>
                                                    ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                                    </View>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                       
                    </footer>
                </div>

            </div>

        </div >
    )
}



function mapStateToProps(state) {
    return {
        PatientTimeline: state.PatientTimeline
    }
}


function mapDispatchToProps(disptach) {
    return {
        getTimeline: (pageSize, pageNumber, fromDate, toDate) => disptach(fetchTimelineFromApi(pageSize, pageNumber, fromDate, toDate)),
        //  getMoreTimeline: (pageSize, pageNumber,) => disptach(fetchMoreTimelineFromApi(pageSize, pageNumber))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimelineHome)
//export default TimelineHome
