import {
    START_SEARCH_APPOINTMENTN,
    START_SEARCH_APPOINTMENTN_SUCCESS,
    START_SEARCH_APPOINTMENTN_FAILURE,
    START_SEARCH_APPOINTMENTN_FINISHED,

} from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";


export const searchAppointmentFromApi = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&FromDate=' + fromDate + '&ToDate=' + toDate
            console.log("SearchString==>"+queryString)
            getPaitentAppointments(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log(JSON.stringify(json));
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json[0].entry)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}


const searchAppointment = () => {
    return {
        type: START_SEARCH_APPOINTMENTN
    }
}

//data success
const searchAppointmentSucess = (data) => {
    return {
        type: START_SEARCH_APPOINTMENTN_SUCCESS,
        data,
    }
}

//data failed
const searchAppointmentFail = () => {
    return {
        type: START_SEARCH_APPOINTMENTN_FAILURE
    }
}
const searchAppointmentFinished = () => {
    return {
        type: START_SEARCH_APPOINTMENTN_FINISHED
    }
}