import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
import renderHTML from "react-render-html";

const DiagnosisHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={'card-header text-white bg-warning'}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>Recorded On</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.date + " " + props.month}</Text>
                    </View>
                </div>
                <div className="card-body">
                    {renderHTML(props.comments)}
                </div>
            </div>
        </div>
    );
}

export default DiagnosisHandler
