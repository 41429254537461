import {
    GETTING_TREATMENTOFCHOICE_MEDS,
    GETTING_LONG_TREATMENTOFCHOICE_SUCCESS,
    GETTING_LONG_TREATMENTOFCHOICE_MORE,
    GETTING_LONG_TREATMENTOFCHOICE_FAILED,
    GETTING_LONG_TREATMENTOFCHOICE_FINISHED
} from "../constants/Constant";
import getTreatmentOfChoiceMedication from "../services/TreatmentOfChoiceService";
import getUserId from "../core/GetToken";



export const fetchTreactOfChoiceFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getLongTreatmentOfChoice())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=10&PageNumber='+pageNumber+'&TypeID=1'
            console.log(queryString);
            getTreatmentOfChoiceMedication(queryString)
                .then(data => data.json())
                .then(json => {
                   // console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getTreatmentOfChoiceSuccess(json[0].entry)) : dispatch(getTreatmentOfChoiceFinished())
                })
                .catch(err => dispatch(getTreatmentOfChoiceFailed(err)))
        })
    }
}
//load more 
export const fetchTreactOfChoiceMoreFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getLongTreatmentOfChoice())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=10&PageNumber='+pageNumber+'&TypeID=1'
            console.log(queryString);
            getTreatmentOfChoiceMedication(queryString)
                .then(data => data.json())
                .then(json => {
                   // console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getTreatmentOfChoiceMoreSuccess(json[0].entry)) : dispatch(getTreatmentOfChoiceFinished())
                })
                .catch(err => dispatch(getTreatmentOfChoiceFailed(err)))
        })
    }
}
//start getting data
const getLongTreatmentOfChoice = () => {
    return {
        type: GETTING_TREATMENTOFCHOICE_MEDS
    }
}

//data is success
const getTreatmentOfChoiceSuccess = (data) => {
    return {
        type: GETTING_LONG_TREATMENTOFCHOICE_SUCCESS,
        data
    }
}
//data is success
const getTreatmentOfChoiceMoreSuccess = (data) => {
    return {
        type: GETTING_LONG_TREATMENTOFCHOICE_MORE,
        data
    }
}

//data failed
const getTreatmentOfChoiceFailed = () => {
    return {
        type: GETTING_LONG_TREATMENTOFCHOICE_FAILED
    }
}

const getTreatmentOfChoiceFinished = () => {
    return{
        type:GETTING_LONG_TREATMENTOFCHOICE_FINISHED
    }
}