import { FETCHING_PEOPLE, FETCHING_LONGTERM_PEOPLE_FINISHED, FETCHING_PEOPLE_SUCCESS, FETCHING_PEOPLE_SUCCESS_MORE, FETCHING_PEOPLE_FAILURE, FETCHING_LONGTERM_PEOPLE, FETCHING_LONGTERM_PEOPLE_SUCCESS, FETCHING_LONGTERM_PEOPLE_SUCCESS_MORE, FETCHING_LONGTERM_PEOPLE_FAILURE } from '../constants/Constant'
import getAllDiagnosis from "../services/DiagnosisServices";
import getUserId from "../core/GetToken";




/******************************** LT *******************************************/

//return long terms diagnosis
export const fetchLongTermFromApi = (pageSize, pageNumber, diagType) => {
    return (dispatch) => {
        dispatch(getPeopleLong())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=2'
            getAllDiagnosis(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getPeopleLongSuccess(json[0].entry)) : dispatch(getPeopleLongFinshed())
                })
                .catch(err => dispatch(getPeopleLongFailed(err)))
        })
    }
}

//featch more diagnosis pagination call
export const fetchMoreLongTermFromApi = (pageSize, pageNumber, diagType) => {
    return (dispatch) => {
        dispatch(getPeopleLong())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=2'
            console.log('For Long: ' + queryString)
            getAllDiagnosis(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(queryString)
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getPeopleLongMoreSuccess(json[0].entry)) : dispatch(getPeopleLongFinshed())
                    } else {
                        dispatch(getPeopleLongFinshed())
                    }
                })
                .catch(err => dispatch(getPeopleLongFailed(err)))
        })
    }
}

/******************************** LT *******************************************/


/******************************** AT *******************************************/
export const fetchPeopleFromApi = (pageSize, pageNumber, diagType) => {
    return (dispatch) => {
        // diagType === 'All' ? dispatch(getPeople()) : dispatch(getPeopleLong())
        dispatch(getPeople())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=0'
            console.log(queryString);
            getAllDiagnosis(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getPeopleSuccess(json[0].entry)) : dispatch(getPeopleLongFinshed())
                })
                .catch(err => dispatch(getPeopleFailed(err)))
        })
    }
}

//featch more diagnosis pagination call
export const fetchMorePeopleFromApi = (pageSize, pageNumber, diagType) => {
    return (dispatch) => {
        // diagType === 'All' ? dispatch(getPeople()) : dispatch(getPeopleLong())
        dispatch(getPeople())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=0'
            //console.log(queryString)
            getAllDiagnosis(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(diagType)
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getPeopleMoreSuccess(json[0].entry)) : dispatch(getPeopleLongFinshed())
                    } else {
                        dispatch(getpeoplFinshed())
                    }
                })
                .catch(err => diagType === 'All' ? dispatch(getPeopleFailed(err)) : dispatch(getPeopleLongFailed(err)))
        })
    }
}

/******************************** AT *******************************************/





//ALL DIAGNOSIS LOADS HERE
const getPeople = () => {
    return {
        type: FETCHING_PEOPLE
    }
}

const getPeopleSuccess = (data) => {
    return {
        type: FETCHING_PEOPLE_SUCCESS,
        data,
    }
}

const getPeopleMoreSuccess = (data) => {
    return {
        type: FETCHING_PEOPLE_SUCCESS_MORE,
        data,
    }
}

const getPeopleFailed = () => {
    return {
        type: FETCHING_PEOPLE_FAILURE
    }
}

const getpeoplFinshed = () => {
    return {
        type: FETCHING_LONGTERM_PEOPLE_FINISHED
    }
}



//ALL LONG TERM DIAGNOSIS GOES HERE
const getPeopleLong = () => {
    return {
        type: FETCHING_LONGTERM_PEOPLE
    }
}

const getPeopleLongSuccess = (payload) => {
    return {
        type: FETCHING_LONGTERM_PEOPLE_SUCCESS,
        payload,
    }
}

const getPeopleLongMoreSuccess = (payload) => {
    return {
        type: FETCHING_LONGTERM_PEOPLE_SUCCESS_MORE,
        payload,
    }
}

const getPeopleLongFailed = () => {
    return {
        type: FETCHING_LONGTERM_PEOPLE_FAILURE
    }
}

const getPeopleLongFinshed = () => {
    return {
        type: FETCHING_LONGTERM_PEOPLE_FINISHED
    }
}
