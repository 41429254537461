import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, Button, StyleSheet, StatusBar, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";

import { fetchAllMedsFromApi } from "../../../shared/actions/AllMedicationActions";
import MedicationHandler from "../../components/MedicationHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
const AllMedications = props => {

    const { AllMedeications, isGetting } = props.AllMedeications;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number


    function capitalize(word) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    useEffect(() => {
        //alert('Called Future')
        props.getMed(pageSize, pageNumber);
    }, [])
    return (
        <div>
            {
                isGetting && <View style={{ textAlign: 'center', justifyContent: 'center', marginBottom: 1, marginTop: 2 }}><ActivityIndicator size="large" color="#00A1DE" /></View>
            }
            {
                typeof (AllMedeications) !== 'undefined' && AllMedeications.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        data={AllMedeications}
                        renderItem={({ item }) => (


                            <MedicationHandler
                                medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                medicationDetails={item.resource.hasOwnProperty('medicationCodeableConcept') ? capitalize(item.resource.medicationCodeableConcept.coding[0].display) : 'N/A'} //
                                directions={item.resource.hasOwnProperty('dosageInstruction') ? item.resource.dosageInstruction[0].text : 'No'} // item.resource.dosageInstruction[0].text
                                checking={item.resource.extension[1].valueString}
                                quantity={item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                endDate={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                startDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                            />
                            // <MedicationWebHandler
                            //     medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                            //     medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                            //     medicationDetails={capitalize(item.resource.medicationCodeableConcept.coding[0].display)}
                            //     directions={item.resource.dosageInstruction[0].text}
                            //     checking={'Period: ' + item.resource.extension[1].valueString}
                            //     quantity={'Quantity: ' + item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                            //     presby={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                            //     pratname={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                            // />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}

                    />
                ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>

            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        AllMedeications: state.AllMedeications
    }
}

function mapDispatchToProps(disptach) {
    return {
        getMed: (pageSize, pageNumber) => disptach(fetchAllMedsFromApi(pageSize, pageNumber)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllMedications);

// export default AllMedications
