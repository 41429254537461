import * as base from '../core/BaseServicesPost';
import { postWithJson } from '../core/postWithJson';


//service endpoint
const serviceUri = 'Patient/ChangePassword';

//export query
// const changePasswrodService = (queryString, jsonParameter) => base.post(`${serviceUri}${queryString}`);
const changePasswrodService = (qString, jsonParameter) => postWithJson(`${serviceUri}${qString}`, jsonParameter);
export default changePasswrodService;