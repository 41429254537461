import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AppointmentHandler from "../components/AppointmentHandler";
import FutureAppointmentView from "./AppointmentTabs/FutureAppointmentView";
import CompleteAppointmentView from "./AppointmentTabs/CompleteAppointmentView";
import MissedAppointmentView from "./AppointmentTabs/MissedAppointmentView";
import CompleteAppointmentViewcopy from "./AppointmentTabs/CompleteAppointmentViewcopy";
import SearchAppointmentView from "./AppointmentTabs/SearchAppointmentView";
import { validatePatientTokenFromApi } from "../../shared/actions/TokenValidActions";
import loadjs from "loadjs";


import { View } from "react-native-web";
import { Tabs } from "antd";
import 'antd/dist/antd.css';
const { TabPane } = Tabs;
//var loadjs = require('loadjs');
const Appointments = props => {

    const [isloaded, setIsload] = React.useState(false);
    const { TokenValidator, isChecking } = props.TokenValidator;
    React.useEffect(() => {

        // loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
        // loadjs.ready('foobar', function () {
        //     setIsload(true);
        // });


        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);


    }, [])

    useEffect(() => {
        //props.validateToken();
    }, [])
    return (
        <div>

            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Appointments</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Appointments</h2>
                                            <div className="item-wrapper">
                                                <Tabs defaultActiveKey="1" centered >
                                                    <TabPane tab="Future" key="1" style={{ backgroundColor: '#f7f7f7', padding: 10 }}>
                                                        <FutureAppointmentView />
                                                    </TabPane>
                                                    <TabPane tab="Completed" key="2">
                                                        <CompleteAppointmentView />

                                                    </TabPane>
                                                    <TabPane tab="Missed" key="3">
                                                        <MissedAppointmentView />
                                                    </TabPane>
                                                    <TabPane tab="Cancelled" key="4">
                                                        <CompleteAppointmentViewcopy />
                                                    </TabPane>
                                                    <TabPane tab="Search" key="5">
                                                        <SearchAppointmentView />
                                                    </TabPane>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                      
                    </footer>
                </div>

            </div>

        </div >
    )
}


function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator
    }
}
function mapDispatchToProps(disptach) {
    return {
        validateToken: () => disptach(validatePatientTokenFromApi())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appointments)
// export default
