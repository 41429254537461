import { START_VALIDATION, START_VALIDATION_SUCCESS, START_VALIDATION_FAILED } from "../constants/Constant";
import checkPatientToken from "../services/TokenValidationService";
import getUserId from "../core/GetToken";

export const validatePatientTokenFromApi = () => {
    return (dispatch) => {
        dispatch(validateToken())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token;
            checkPatientToken(queryString)
                .then(data => data.json())
                .then(json => {
                    console.log("My Json Data is:  "+json)
                    dispatch(validateTokenSuccess(json))
                })
                .catch(err => dispatch(validateTokenFailed(err)))
        })
    }
}


const validateToken = () => {
    return {
        type: START_VALIDATION
    }
}


const validateTokenSuccess = (data) => {
    return {
        type: START_VALIDATION_SUCCESS,
        data
    }
}

const validateTokenFailed = () => {
    return {
        type: START_VALIDATION_FAILED
    }
}